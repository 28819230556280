<template>
  <v-app id="app">
    <top-bar></top-bar>
    <side-bar v-if="showSideBar"></side-bar>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>
<script>
import TopBar from '@Share/components/TopBar.vue';
import SideBar from '@Share/components/SideBar.vue';

export default {
  name: "default-layout",
  components: {
    TopBar,
    SideBar,
  },
  computed: {
    showSideBar() {
      return !this.$store.getters['COMPARE_TOOL/IS_COMPARING_MODE'];
    },
  },
  mounted() {

  }
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
