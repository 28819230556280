import store from "@/store";

const Autodesk = window.Autodesk;

class CompareToolExtension extends Autodesk.Viewing.Extension {
	constructor(viewer, options) {
		super(viewer, options);
		this.viewer = viewer;
		this.compareButton = null;
		this.diffToolExtension = null;
		this.loadedModels = new Map();
		this.defaultVisibleModels = [];
	}

	static get extensionId() {
		return 'CompareToolExtension';
	}

	load() {
		console.log('CompareToolExtension loaded');
		let existingExtensions = this.viewer.getLoadedExtensions();
		if (!!existingExtensions?.length && this.viewer?.getLoadedExtensions()?.indexOf('Autodesk.DiffTool') !== -1) {
			this.diffToolExtension = this.viewer.getExtension('Autodesk.DiffTool');
			store.commit('COMPARE_TOOL/SET_IS_COMPARING_MODE', true);
		}
		return true;
	}

	unload() {
		console.log('CompareToolExtension unloaded');
		if (this.viewer.id === window.NOP_VIEWER.id) {
			store.commit('COMPARE_TOOL/SET_IS_COMPARING_MODE', false);
		}
		if (this.compareButton) {
			this.removeCompareButton();
		}
		return true;
	}

	onToolbarCreated() {
		console.log(`onToolbarCreated`);
		this.addCompareButton();
	}

	addCompareButton() {
		const compareButton = new Autodesk.Viewing.UI.Button('compareButton');
		compareButton.addClass('compare-button');
		compareButton.setToolTip('Compare');

		compareButton.onClick = () => {
			this.showComparePopup();
		};

		this.compareButton = compareButton;

		let group = this.viewer.toolbar.getControl('compare-group');

		if (!group) {
			group = new Autodesk.Viewing.UI.ControlGroup('compare-group');
			this.viewer.toolbar.addControl(group);
		}

		group.addControl(compareButton);

		this.compareButton.container.querySelector('.compare-button div').className = 'mdi mdi-select-compare';
		this.compareButton.container.querySelector('.compare-button div').style.fontSize = '24px';
	}

	removeCompareButton() {
		this.viewer.toolbar.removeControl(this.compareButton);
	}

	showComparePopup() {
		store.commit("COMPARE_TOOL/SET_SHOW_COMPARE_POPUP", true);
	}

	async startCompare(compareNode1, compareNode2) {
		store.commit('COMPARE_TOOL/SET_IS_COMPARING_MODE', true);

		this.defaultVisibleModels = this.viewer.getVisibleModels();

		this.hideAllModels();

		// Load models and set up views
		const model1 = await this.loadModelAndView(compareNode1);
		console.log(`%c✅ ModelA is loaded`, `color: green; font-size: 16px; font-weight: bold;`);
		const model2 = await this.loadModelAndView(compareNode2);
		console.log(`%c✅ ModelB is loaded`, `color: green; font-size: 16px; font-weight: bold;`);

		const config = {
			primaryModels: [model1],
			diffModels: [model2],
			versionA: compareNode1.name,
			versionB: compareNode2.name,
			mimeType: 'application/vnd.autodesk.revit',
			diffMode: 'overlay',
		};

		await this.loadCompareTool(config);
	}

	async loadCompareTool(config) {
		// Load Autodesk.DiffTool extension if not already loaded
		this.diffToolExtension = await this.viewer.loadExtension('Autodesk.DiffTool', config);

		if (this.diffToolExtension) {
			const { viewerToolbarDiffGroup, toolbarControls } = this.diffToolExtension;
			// viewerToolbarDiffGroup.removeControl(viewerToolbarDiffGroup._controls[0]);
			// viewerToolbarDiffGroup.removeControl(viewerToolbarDiffGroup._controls[0]);
			// viewerToolbarDiffGroup.addControl(toolbarControls.overlayButton)
			// viewerToolbarDiffGroup.addControl(toolbarControls.sideBySideButton)
			// viewerToolbarDiffGroup.addControl(toolbarControls.changesDialogButton)

			// Adjust diff tool buttons click
			const originalHandler = toolbarControls.sideBySideButton.onClick;
			toolbarControls.sideBySideButton.onClick = () => {
				if (this.diffToolExtension.diffMode === 'sidebyside') {
					toolbarControls.overlayButton.onClick();
					return;
				}

				originalHandler();
			}

			
			let button = new Autodesk.Viewing.UI.Button('exitCompareButton');
			button.addClass('exit-compare-button');
			button.setToolTip('Exit Compare');
			button.onClick = () => {
				this.exitCompareMode();
			};

			viewerToolbarDiffGroup.addControl(button);

			let icon = button.container.querySelector('.adsk-button-icon');
			if (icon) {
				icon.className = 'mdi mdi-exit-to-app';
				icon.style.fontSize = '24px';
			}
		}
	}

	hideAllModels() {
		const models = this.viewer.getAllModels();
		models.forEach((model) => {
			this.viewer.hideModel(model);
		});
	}

	async loadModelAndView(compareNode) {
		return new Promise((resolve) => {
			const { urn, node } = compareNode;
			const modelKey = `${urn}_${node.guid}`;

			let model;
			if (this.loadedModels.has(modelKey)) {
				model = this.loadedModels.get(modelKey);
				this.viewer.showModel(model);
				resolve(model);
			} else {
				Autodesk.Viewing.Document.load(`urn:${urn}`, (doc) => {
					this.onDocumentLoadSuccess(doc, node.guid, modelKey).then(resolve);
				}, this.onDocumentLoadFailure);
			}
		});
	}

	async onDocumentLoadSuccess(doc, viewId, modelKey) {
		return new Promise((resolve, reject) => {
			const options = {
				// preserveView: true,
				keepCurrentModels: true,
				modelSpace: true,
				// globalOffset: { x: 0, y: 0, z: 0 },
			};

			let root = doc.getRoot();

			let views = root.search({
				guid: viewId,
			});

			if (views.length) {
				let view = views[0];
				this.viewer.loadDocumentNode(doc, view, options)
					.then((model) => {
						this.loadedModels.set(modelKey, model);
						resolve(model);
					})
					.catch(reject);
			} else {
				console.error('View not found');
			}
		});
	}

	onDocumentLoadFailure(viewerErrorCode) {
		console.error('Document load failed', viewerErrorCode);
	}

	exitCompareMode() {
		store.commit('COMPARE_TOOL/SET_IS_COMPARING_MODE', false);

		if (this.diffToolExtension) {
			this.viewer.unloadExtension('Autodesk.DiffTool');
			this.diffToolExtension = null;
		}

		this.loadedModels.forEach((model) => {
			this.viewer.hideModel(model);
		});

		this.defaultVisibleModels.forEach((model) => {
			this.viewer.showModel(model);
		});
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(CompareToolExtension.extensionId, CompareToolExtension);

export default CompareToolExtension;