<template>
    <v-sheet>
        <div class="recent-button" v-if="!isDialogVisible">
            <v-icon class="icon" @click="openPrevious">mdi-menu-left</v-icon>
            <div @click="isDialogVisible = !isDialogVisible">
                <svg fill="white" viewBox="0 0 24 24" data-testid="gridFilled" aria-label="gridFilled" width="24"
                    height="24" class="sc-dGCmGc bZqktD">
                    <path
                        d="M8,10.75H5A2.75,2.75,0,0,1,2.27,8V5A2.76,2.76,0,0,1,5,2.27H8A2.76,2.76,0,0,1,10.75,5V8A2.75,2.75,0,0,1,8,10.75Zm11,0H16A2.75,2.75,0,0,1,13.25,8V5A2.75,2.75,0,0,1,16,2.27h3A2.76,2.76,0,0,1,21.73,5V8A2.75,2.75,0,0,1,19,10.75Zm-11,11H5A2.76,2.76,0,0,1,2.27,19V16A2.75,2.75,0,0,1,5,13.25H8A2.75,2.75,0,0,1,10.75,16v3A2.76,2.76,0,0,1,8,21.73Zm11,0H16A2.75,2.75,0,0,1,13.25,19V16A2.75,2.75,0,0,1,16,13.25h3A2.75,2.75,0,0,1,21.73,16v3A2.76,2.76,0,0,1,19,21.73Z">
                    </path>
                </svg>
            </div>
            <v-icon @click="openNext">mdi-menu-right</v-icon>
        </div>

        <v-sheet color="#000000" elevation="10" style="opacity: 0.8;" height="auto;" v-if="isDialogVisible">
            <v-btn @click="tab = true" style="background: none;" :active="tab">Projects</v-btn>
            <v-btn @click="tab = false" style="background: none;" :active="!tab">Recent Views</v-btn>
            <div class="close-button">
                <v-icon @click="openPrevious">mdi-menu-left</v-icon>
                <v-icon @click="openNext">mdi-menu-right</v-icon>
                <v-icon @click="isDialogVisible = false">mdi-close</v-icon>
            </div>
            <v-card v-if="tab" style="background: none;">
                <v-list class="horizontal-list">
                    <v-list-item v-for="(item) in SiblingProjects" :key="item.id" :value="item">
                        <div @click="openLatestVersion(item)"
                            style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <img :src="item?.thumbnail" style="width: auto; height: 100%; max-height: 200px; cover"
                                :class="{ 'selected-item': selectedItem === item }" @click="changeNode(item)" />
                            <div style="color: white">{{ item?.name }}</div>
                        </div>
                    </v-list-item>
                </v-list>
            </v-card>
            <v-card style="background: none;" v-else>
                <v-list class="horizontal-list">
                    <v-list-item v-for="(item) in RecentOpenedViews" :key="item?.data?.guid" :value="item">
                        <div
                            style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <img :src="item?.imgUrl" style="width: auto; height: 100%; max-height: 200px; " cover
                                :class="{ 'selected-item': selectedItem === item }" @click="changeNode(item)" />
                            <div style="color: white">{{ item?.data?.name }}</div>
                        </div>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-sheet>
    </v-sheet>


</template>

<script>
import modelService from '@Models/service/model.service';
import { toast } from 'vue3-toastify';

export default {
    props: ['onClose'],
    data() {
        return {
            recentOpenedViews: [],
            selectedItem: null,
            tab: true,
            isDialogVisible: false,
        };
    },
    methods: {
        openPrevious() {
            if (this.tab) {
                const index = this.SiblingProjects.indexOf(this.selectedItem)

                if (index > 0) {
                    this.openLatestVersion(this.SiblingProjects[index - 1])
                }
            }
            else{
                const index = this.RecentOpenedViews.indexOf(this.selectedItem)
                
                if (index > 0) {
                    this.changeNode(this.RecentOpenedViews[index - 1])
                }
            }
        },
        
        openNext() {
            if (this.tab) {
                const index = this.SiblingProjects.indexOf(this.selectedItem)

                if (index + 1 < this.SiblingProjects.length) {
                    this.openLatestVersion(this.SiblingProjects[index - 1])
                }
            }
            else {
                const index = this.RecentOpenedViews.indexOf(this.selectedItem)
                if (index + 1 < this.RecentOpenedViews.length) {
                    this.changeNode(this.RecentOpenedViews[index + 1])
                }
            }
        },
        changeNode(item) {
            this.selectedItem = item;
            window.NOP_VIEWER.loadDocumentNode(window.loadedForgeDocument, item)
        },
        openLatestVersion(item) {
            let currentModelData = this.$store.getters['MODELS/CURRENT_MODEL_DATA'];
            let user = this.$store.getters['ACCOUNT/USER'];
            let version = item.versions[0]
            let model = {
                hubName: currentModelData.hubName,
                projectId: version.projectId,
                projectName: currentModelData.projectName,
                modelName: item.name,
                version: version.name,
                urn: version.urn,
                storageUrl: version.ref,
                userId: user.userId,
                userName: user.userName,
                itemId: version.itemId
            };

            modelService.updateModel(model)
                .then((data) => {
                    console.log(data);
                    this.dialog = false;
                    this.$router.push({ name: 'ModelViewer', params: { id: data.id }, query: { ...this.$route.query } }).then(() => {
                        window.location.reload();
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.dialog = false;
                    toast.error('Failed to save local changes to the model');
                });
        },
    },
    mounted() {
        const currentView = this.$store.getters['View_Id']
        const view = this.RecentOpenedViews.find(x=>x.data.guid == currentView)
        if(view){
            this.selectedItem = view
        }
    },
    computed: {
        RecentOpenedViews() {
            return this.$store.getters['RECENTLY_OPENED_VIEWS'];
        },
        SiblingProjects() {
            return this.$store.getters['SIBLING_PROJECTS'];
        },
        CurrentViewId(){
            return this.$store.getters['View_Id']
        },
        CurrentItemId() {
            return this.$store.getters['MODELS/CURRENT_MODEL_DATA'].itemId
        }
    },
    watch: {
        RecentOpenedViews(value) {
            this.recentOpenedViews = value;
        },
        CurrentViewId(value) {
            this.tab = false
            const view = this.RecentOpenedViews.find(x => x.data.guid == value)
            if (view) {
                this.selectedItem = view
            }
        },
    },
    components: {
    },
};
</script>

<style scoped>
.full-width-container {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: 10000; 
}
.horizontal-list {
    background: none;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
}

.horizontal-list-item {
    flex: 0 0 auto;
    margin-right: 16px;
}

.close-button {
    width: 25px;
    height: 25px;
    display: flex;
      align-items: center;
      justify-content: center;
    position: absolute;
    top: 5px;
    right: 50px;
    cursor: pointer;
}

.close-button i {
    color: #fff;
    font-size: x-large;
    z-index: 1200;
}

.close-button i:hover {
    opacity: 0.4;
}  
.selected-item {
    border: 4px solid blue;
    box-sizing: border-box;
}
 .recent-button {
     display: flex;
     position: absolute;
     /* z-index: 2; */
     bottom: 50px;
     right: 40px;
     padding: 5px;
     border-radius: 5px;
     cursor: pointer;
     background-color: rgba(0, 0, 0, 0.6);
 }

 .recent-button i {
     color: #fff !important;
     font-size: x-large !important;
 }

 .recent-button i:hover {
     opacity: 0.4;
 }

 .recent-button svg:hover {
     opacity: 0.4;
 }
</style>
