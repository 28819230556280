/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import ViewerToolkit from '../Viewer.Toolkit';
import { ForgeService } from '../../store/modules/ForgeService';
import { toast } from 'vue3-toastify';
import '../Viewing.Extension.Transform/TransformGizmos';
import { ManipulatedElement, ManipulationTypes } from '@/models/ForgeModels';
import store from '@/store';
import Rotate2DTool from './Viewing.Tool.Rotate2D';
import Translate2DTool from './Viewing.Tool.Translate2D';
import { isTouchDevice } from '@/services/compat';
const { Autodesk, THREE } = window;
import { getWorldBoundingBox } from '@/store/modules/ForgeService';
import { DeleteElementCommand } from '@/utils/undoRedoManager';
import Manipulation2DExtension from '@/extensions/Viewing.Extension.Manipulation2D/index.js';
export default class ElementClone2D extends Autodesk.Viewing.ToolInterface {
    constructor(viewer, rotate2dTool, translate2dTool) {
        super();

        this.viewer = viewer;

        this.names = ['Viewing.Clone2D.Tool'];
        this.priority = 99;

        this.customOverlayName = 'manipulated-elements-2d-overlay';

        this.clonedObjectColor = 0x00ff00;
        this.selectedObjectColor = 0x0000ff;
        this.hoveredObjectColor = 0x11aaff;

        this.saveTimeout = null;

        this.hoveredObject = null;
        this.selectedObjects = [];

        this.clonedObjects = [];

        this.translationDiffWorld = new THREE.Vector3();
        this.translationDiffSheet = new THREE.Vector3();
        this.transformMesh = null;
        this.transformControlTx = null;
        this.isDragging = false;
        this.onTxChange = this.onTxChange.bind(this);
        this.onCameraChanged = this.onCameraChanged.bind(this);

        delete this.register;
        delete this.deregister;
        delete this.activate;
        delete this.deactivate;
        delete this.getPriority;
        delete this.handleMouseMove;
        delete this.handleButtonDown;
        delete this.handleButtonUp;
        delete this.handleSingleClick;
        delete this.handleSingleTap;
        delete this.handleDoubleTap;
        delete this.handleGesture;

        // this.viewer.toolController.registerTool(this);
        this.rotate2dTool = null;
        this.translate2dTool = null;
        // this.rotate2dTool = new Rotate2DTool(viewer, this);
        //  this.translate2dTool = new Translate2DTool(viewer, this);
    }

    enable(enable) {
        var name = this.getName();

        if (enable) {
            this.viewer.toolController.activateTool(name);
        } else {
            this.clearControls();
            this.removeOverlayScene();
            this.clonedObjects = [];
            this.viewer?.toolController?.deactivateTool(name);
        }

        if (this.rotate2dTool) {
            this.rotate2dTool.enable(enable);
        }

        if (this.translate2dTool) {
            this.translate2dTool.enable(enable);
        }
    }

    getNames() {
        return this.names;
    }

    getName() {
        return this.names[0];
    }

    forceUpdate() {
        let worldPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];

        let position = ViewerToolkit.worldToSheet(
            worldPosition,
            this.viewer.model,
            store.getters['VIEWER/TRANSFORM_MATRIX'],
        );

        if (!position) {
            console.log('Failed to translate element');
            return;
        }

        let lastSheetPosition = this.transformControlTx.position.clone();
        let lastWorldPosition = ViewerToolkit.sheetToWorld(
            this.viewer,
            lastSheetPosition,
            this.viewer.model,
            store.getters['VIEWER/GLOBAL_OFFSET'],
        );
        lastWorldPosition = new THREE.Vector3(
            lastWorldPosition.x,
            lastWorldPosition.y,
            lastWorldPosition.z,
        );

        this.translationDiffSheet = position.clone().sub(lastSheetPosition);
        this.translationDiffWorld = worldPosition.clone().sub(lastWorldPosition);

        this.transformControlTx.setPosition(position);

        this._update();
    }

    _update(ignoreTimeout = false) {
        if (!this.selectedObjects?.length || !this.transformControlTx.visible) return;

        let worldPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];

        worldPosition = new THREE.Vector3(worldPosition.x, worldPosition.y, worldPosition.z);

        if (!worldPosition) {
            console.log('Failed to translate element');
            return;
        }

        this.selectedObjects.forEach((object) => {
            let element = store.getters.MANIPULATED_ELEMENTS.find((e) => e.dbId == object.name);

            if (!element) return;

            let position = new THREE.Vector3(
                element.position.x,
                element.position.y,
                element.position.z,
            );

            store.dispatch('UpdateManipulatedElementPosition', {
                dbId: object.name,
                position: position.add(this.translationDiffWorld),
                is2D: true,
            });

            this.applyTranslationToVertices(object, this.translationDiffSheet);
        });

        if (this.selectedObjects.length == 1) {
            let rotate2dTool = this.viewer.toolController.getTool('Viewing.Rotate2D.Tool');

            if (rotate2dTool) {
                rotate2dTool.drawControl();
            }
        }

        this.viewer.impl.sceneUpdated(true);

        if (!ignoreTimeout) {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(() => {
                store.dispatch('SaveLocalModelChanges');
            }, 2000);
        } else {
            store.dispatch('SaveLocalModelChanges');
        }
    }

    applyTranslationToVertices(object, translationDiff) {
        // Assuming the object's geometry is an instance of THREE.Geometry or THREE.BufferGeometry
        if (object.geometry instanceof THREE.Geometry) {
            // For THREE.Geometry (deprecated in recent three.js versions)
            object.geometry.vertices.forEach((vertex) => {
                vertex.add(translationDiff);
            });
            object.geometry.verticesNeedUpdate = true;
        } else if (object.geometry instanceof THREE.BufferGeometry) {
            // For THREE.BufferGeometry
            const positionAttribute = object.geometry.attributes.position;
            for (let i = 0; i < positionAttribute.count; i++) {
                let vertex = new THREE.Vector3();
                vertex.fromBufferAttribute(positionAttribute, i);
                vertex.add(translationDiff);
                positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
            }
            positionAttribute.needsUpdate = true;
        }

        // If the object has a bounding box or sphere, it may need updating
        object.geometry.computeBoundingBox();
        object.geometry.computeBoundingSphere();
    }

    register() {
        return true;
    }

    deregister() {
        return true;
    }

    getPriority() {
        return this.priority;
    }

    activate(name) {
        console.log('ElementClone2D activated');

        this.addContextMenuOption();

        this.addEventListeners();

        var bBox = this.viewer.model.getBoundingBox();

        var size =
            Math.max(bBox.max.x - bBox.min.x, bBox.max.y - bBox.min.y, bBox.max.z - bBox.min.z) *
            0.8;

        if (!this.viewer?.impl?.overlayScenes[this.customOverlayName]) {
            this.viewer.impl.createOverlayScene(this.customOverlayName);
        }

        this.snapper = new Autodesk.Viewing.Extensions.Snapping.Snapper(this.viewer, {
            renderSnappedGeometry: true,
            renderSnappedTopology: true,
        });

        this.viewer.toolController.registerTool(this.snapper);

        this.viewer.toolController.activateTool(this.snapper.getName());

        return true;
    }

    createControls() {
        var bBox = this.viewer.model.getBoundingBox();

        var size =
            Math.max(bBox.max.x - bBox.min.x, bBox.max.y - bBox.min.y, bBox.max.z - bBox.min.z) *
            0.8;

        if (this.transformControlTx) {
            this.viewer.impl.removeOverlay(this.customOverlayName, this.transformControlTx);
        }

        this.transformControlTx = new THREE.TransformControls(
            this.viewer.impl.camera,
            this.viewer.impl.canvas,
            'translate',
        );

        this.transformControlTx.setSize(size);

        this.transformControlTx.visible = true;

        this.viewer.impl.addOverlay(this.customOverlayName, this.transformControlTx);

        this.transformMesh = this.createTransformMesh();

        this.transformControlTx.attach(this.transformMesh);

        let selectedObjectsBox = null;

        this.selectedObjects.forEach((object) => {
            if (!selectedObjectsBox) {
                selectedObjectsBox = object.geometry?.boundingBox;
            } else {
                selectedObjectsBox.union(object.geometry?.boundingBox);
            }
        });

        if (!selectedObjectsBox) return;

        let center = selectedObjectsBox.getCenter();

        this.transformControlTx.setPosition(center);

        let mappedPosition = ViewerToolkit.sheetToWorld(
            this.viewer,
            center,
            this.viewer.model,
            store.getters['VIEWER/GLOBAL_OFFSET'],
        );
          if (!mappedPosition) {
                    mappedPosition = ViewerToolkit.map2DTo3D(
                        center,
                        this.translate2dTool.elements2dPoints,
                        this.translate2dTool.elements3dPoints,
                    );
          }
        if (!mappedPosition) {
            console.log('Failed to get start position');
            return;
        }

        store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', mappedPosition.clone());

        store.commit('VIEWER/SET_START_MANIPULATION_POSITION', mappedPosition.clone());
    }

    clearControls() {
        this.viewer?.impl?.removeOverlay(this.customOverlayName, this.transformControlTx);

        this.transformControlTx = null;

        store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', null);
        store.commit('VIEWER/SET_START_MANIPULATION_POSITION', null);

        this.translationDiffWorld = new THREE.Vector3();
        this.translationDiffSheet = new THREE.Vector3();
    }

    deactivate() {
        console.log('ElementClone2D deactivated');

        this.removeOverlayScene();

        this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

        document.removeEventListener('keydown', this.onKeyDown);

        this.removeContextMenuOption();

        return true;
    }

    load() {
        console.log('ElementClone2D loaded');

        return true;
    }

    unload() {
        console.log('ElementClone2D unloaded');

        return true;
    }

    // handleSingleClick(event, button) {
    //     if (this.transformControlTx?.onPointerDown(event)) return true;

    //     return false;
    // }

    testFunction() {
        console.log('Test function called');
    }

    rotateElementWithAngle(angle, overrideSettings = false) {
        if (!this.selectedObjects.length || !this.transformControlTx) return;

        let angleRad = THREE.Math.degToRad(angle);

        this.rotate2dTool.rotateSelectedClonedElement(angleRad, overrideSettings);
    }

    moveElementToDirection(direction, sensitivity) {
        if (!this.selectedObjects.length || !this.transformControlTx) return;

        let value = sensitivity / 1000;

        let translationDiff = new THREE.Vector3();

        switch (direction) {
            case 'up':
                translationDiff.y = value;
                break;
            case 'down':
                translationDiff.y = -value;
                break;
            case 'left':
                translationDiff.x = -value;
                break;
            case 'right':
                translationDiff.x = value;
                break;
        }

        let position = this.transformControlTx.position.clone().add(translationDiff);

        this.transformControlTx.setPosition(position);

        let mappedPosition = ViewerToolkit.sheetToWorld(
            this.viewer,
            position.clone(),
            this.viewer.model,
            store.getters['VIEWER/GLOBAL_OFFSET'],
        );

        if (!mappedPosition) {
            console.log('Failed to translate element');
            return;
        }

        let lastWorldPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];
        lastWorldPosition = new THREE.Vector3(
            lastWorldPosition.x,
            lastWorldPosition.y,
            lastWorldPosition.z,
        );
        let lastSheetPosition = ViewerToolkit.worldToSheet(
            lastWorldPosition,
            this.viewer.model,
            store.getters['VIEWER/TRANSFORM_MATRIX'],
        );

        this.translationDiffSheet = position.clone().sub(lastSheetPosition);
        this.translationDiffWorld = mappedPosition.clone().sub(lastWorldPosition);

        store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', mappedPosition.clone());

        this._update();

        this.viewer.impl.invalidate(true);
    }

    handleButtonDown(event, button) {
        if (button !== 0) return false;

        this.isDragging = true;

        if (this.transformControlTx?.onPointerDown(event)) return true;

        this.onObjectClick(event);

        return !!this.selectedObjects.length;
    }

    handleButtonUp(event, button) {
        this.isDragging = false;

        if (button !== 0) return false;

        if (this.transformControlTx?.onPointerUp(event)) {
            console.log('Transform control pointer up event triggered');
            return true;
        }

        return !!this.selectedObjects.length;
    }

    handleSingleTap(event) {

        this.onMouseMove(event);

        this.handleButtonDown(event, 0);

        console.log('Single tap event triggered');

        // var ext = this.viewer.getExtension(Manipulation2DExtension.ExtensionId);
        // if (ext && ext.btnClicked && this.selectedObjects?.length) {
        //     this.startManipulatingElement();
        // }
        return false;
    }

    handleDoubleTap(event) {
        // if (this.transformControlTx?.onDoubleTap(event)) return true;

        console.log('Double tap event triggered');

        return false;
    }

    handleGesture(event) {
        if (!isTouchDevice()) return false;

        if (!this.selectedObjects.length) return false;

        switch (event.type) {
            case 'dragstart':
                var accepted = this.transformControlTx?.onPointerDown(event);

                // this.handleButtonDown(event, 0);

                if (accepted) {
                    this.touchType = 'drag';

                    this.isDragging = true;
                }

                return accepted;

            case 'dragmove':
                return this.handleMouseMove(event);

            case 'dragend':
                if (this.touchType === 'drag') {
                    this.handleButtonUp(event, 0);

                    this.touchType = null;

                    return false;
                }

                return false;
        }

        return false;
    }

    handleMouseMove(event) {
        // this.snapper.indicator.clearOverlays();
        this.transformControlTx?.onPointerHover(event);

        if (this.isDragging) {
            let snapPosition = null;

            // if (this.snapper.isSnapped()) {
            //     const result = this.snapper.getSnapResult();

            //     snapPosition = result.intersectPoint.clone();

            //     // Render snapper indicator for visual feedback
            //     this.snapper.indicator.render();
            // }

            if (this.transformControlTx?.onPointerMove(event) && this.selectedObjects.length) {
                // if (snapPosition) {
                //     const newPosition = this.calculateSnapPosition(this.selectedObject, snapPosition);
                //     this.transformControlTx.setPosition(newPosition);
                // }

                let position = this.transformControlTx.position;

                let mappedPosition = ViewerToolkit.sheetToWorld(
                    this.viewer,
                    position.clone(),
                    this.viewer.model,
                    store.getters['VIEWER/GLOBAL_OFFSET'],
                );
                if (!mappedPosition) {
                        mappedPosition = ViewerToolkit.map2DTo3D(
                            position.clone(),
                            this.translate2dTool.elements2dPoints,
                            this.translate2dTool.elements3dPoints,
                        );  
                }
                if (!mappedPosition) {
                    console.log('Failed to translate element');
                    return;
                }

                let lastWorldPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];
                lastWorldPosition = new THREE.Vector3(
                    lastWorldPosition.x,
                    lastWorldPosition.y,
                    lastWorldPosition.z,
                );
                let lastSheetPosition = ViewerToolkit.worldToSheet(
                    lastWorldPosition,
                    this.viewer.model,
                    store.getters['VIEWER/TRANSFORM_MATRIX'],
                );
                if (!lastSheetPosition) {
                    lastSheetPosition = ViewerToolkit.map3DTo2D(
                        lastWorldPosition,
                        this.translate2dTool.elements3dPoints,
                        this.translate2dTool.elements2dPoints,
                    );
                }
                this.translationDiffSheet = position.clone().sub(lastSheetPosition);
                this.translationDiffWorld = mappedPosition.clone().sub(lastWorldPosition);

                store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', mappedPosition.clone());

                this._update();

                return true;
            }

            return false;
        } else {
            this.onMouseMove(event);
        }

        this.viewer.impl.invalidate(true);

        if (this.transformControlTx?.onPointerHover(event)) return true;

        return false;
    }

    calculateSnapPosition(object, snapPoint) {
        // Assuming the object's geometry is centered around its position,
        // and we can use the bounding box to determine its extents.
        const bbox = new THREE.Box3().setFromObject(object);

        // Calculate the object's center for reference
        const objectCenter = bbox.getCenter(new THREE.Vector3());

        // Initialize variables to determine the nearest side
        let nearestSide = objectCenter; // Default to center
        let minDistance = Infinity;

        // Define the potential snap points on the object (corners, edges, faces centers)
        const potentialSnapPoints = [
            // Corners of the bounding box
            bbox.min.clone(),
            bbox.max.clone(),
            new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.max.z),
            new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.min.z),
            new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.min.z),
            new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.max.z),
            new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.max.z),
            new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.min.z),
            // Centers of each face
            new THREE.Vector3(objectCenter.x, bbox.min.y, objectCenter.z), // Bottom face center
            new THREE.Vector3(objectCenter.x, bbox.max.y, objectCenter.z), // Top face center
            new THREE.Vector3(bbox.min.x, objectCenter.y, objectCenter.z), // Left face center
            new THREE.Vector3(bbox.max.x, objectCenter.y, objectCenter.z), // Right face center
            new THREE.Vector3(objectCenter.x, objectCenter.y, bbox.min.z), // Front face center
            new THREE.Vector3(objectCenter.x, objectCenter.y, bbox.max.z), // Back face center
        ];

        // Find the nearest potential snap point to the snapPoint
        potentialSnapPoints.forEach((potentialPoint) => {
            const distance = potentialPoint.distanceTo(snapPoint);
            if (distance < minDistance) {
                minDistance = distance;
                nearestSide = potentialPoint;
            }
        });

        // Return the nearest side's position or the object's center as the new position
        // Depending on your logic, you might snap directly to `nearestSide`,
        // or you might want to adjust this position based on the object's original orientation
        return nearestSide;
    }

    createTransformMesh() {
        var material = new THREE.MeshPhongMaterial({ color: 0xff0000 });

        this.viewer.impl.matman().addMaterial('transform-tool-material', material, true);

        var sphere = new THREE.Mesh(new THREE.SphereGeometry(0.0005, 5), material);

        sphere.position.set(0, 0, 0);

        return sphere;
    }

    onCameraChanged() {
        if (this.transformControlTx) this.transformControlTx.update();
    }

    removeContextMenuOption() {
        this.viewer.unregisterContextMenuCallback('CloneObjects2DContextMenu');
    }

    addContextMenuOption() {
        this.viewer.registerContextMenuCallback('CloneObjects2DContextMenu', (menu, status) => {
            if (status.hasSelected) {
                menu.push({
                    title: 'Clone Element',
                    target: () => this.cloneSelectedElement(),
                });
            }

            if (this.selectedObjects.length) {
                menu.push({
                    title: 'Modify Properties',
                    target: () => this.showPropertiesPanel(),
                });

                menu.push({
                    title: 'Delete Element',
                    target: () => this.deleteSelectedObject(),
                });

                menu.push({
                    title: 'Manipulate Element',
                    target: () => this.startManipulatingElement(),
                });
            }
        });
    }

    addEventListeners() {
        this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);
    }

    onTxChange() {
        this.viewer.impl.sceneUpdated(true);
    }

    onMouseMove(event) {
        let raycaster = ViewerToolkit.pointerToRaycaster(this.viewer, event);

        // Check for intersections with all cloned objects
        const intersects = raycaster.intersectObjects(this.clonedObjects);

        if (intersects.length > 0) {
            const firstIntersectedObject = intersects[0].object;
            if (
                this.hoveredObject !== firstIntersectedObject &&
                this.selectedObjects.indexOf(firstIntersectedObject) === -1
            ) {
                // Reset previous hovered object color
                if (this.hoveredObject) {
                    this.hoveredObject.material.color.setHex(this.clonedObjectColor);
                }

                // Update the hovered object and its color
                this.hoveredObject = firstIntersectedObject;
                this.hoveredObject.material.color.setHex(this.hoveredObjectColor);

                this.viewer.impl.invalidate(true); // Request a rerender
            }
        } else {
            // No intersection found, reset hovered object color
            if (this.hoveredObject) {
                this.hoveredObject.material.color.setHex(this.clonedObjectColor);
                this.hoveredObject = null;
                this.viewer.impl.invalidate(true); // Request a rerender
            }
        }
    }

    onObjectClick(event) {
        if (!this.hoveredObject && !this.selectedObjects.length) return;

        let raycaster = ViewerToolkit.pointerToRaycaster(this.viewer, event);

        if (!raycaster) return;

        const intersects = raycaster.intersectObjects(this.clonedObjects);

        if (intersects.length > 0) {
            this.handleIntersectedObject(intersects[0].object);
        } else {
            this.handleNoIntersection();
        }
    }

    handleIntersectedObject(object) {
        this.hoveredObject = null;
        this.viewer.clearSelection();

        const isExist = this.selectedObjects.some((obj) => obj.name === object.name);

        if (isExist) {
            this.deselectObject(object);
        } else {
            this.selectObject(object);
        }

        this.viewer.impl.invalidate(true);
    }

    deselectObject(object) {
        this.selectedObjects = this.selectedObjects.filter((obj) => obj.name !== object.name);
        object.material.color.setHex(this.clonedObjectColor);

        if (this.transformControlTx?.visible) {
            this.updateControls();
        }
        this.onSelectionChanged();
    }

    selectObject(object) {
        if (!this.transformControlTx?.visible) this.clearSelection();

        object.material.color.setHex(this.selectedObjectColor);
        this.selectedObjects.push(object);

        this.rotate2dTool.clearSelection();

        if (this.transformControlTx?.visible) {
            this.updateControls();
        }
        this.onSelectionChanged();
    }

    handleNoIntersection() {
        if (this.selectedObjects.length) {
            this.rotate2dTool.clearSelection();
            this.clearSelection();
            this.clearControls();
        }
    }

    updateControls() {
        this.clearControls();
        this.createControls();
    }

    clearSelection() {
        if (!this.selectedObjects.length) return;

        this.selectedObjects.forEach((object) => {
            object.material.color.setHex(this.clonedObjectColor);
        });

        this.viewer.impl.invalidate(true);

        this.selectedObjects = [];
    }

    onSelectionChanged() {
        //console.log('selection changed from tool')
        if (this.selectedObjects.length) {
            let isPropertyPanelOpened =
                store.getters['InstanceProperties/IS_PROPERTY_PANEL_OPENED'];

            if (isPropertyPanelOpened) this.showPropertiesPanel();
        }
    }

    showPropertiesPanel() {
        if (!this.selectedObjects.length) return;

        let lastAdded = this.selectedObjects[this.selectedObjects.length - 1];

        let element = store.getters.MANIPULATED_ELEMENTS.find((el) => el.dbId == lastAdded.name);

        if (!element) return;

        const dbId = element.dbId;

        const originalDbId = element.originalDbId;

        this.viewer.getProperties(
            originalDbId,
            (result) => {
                if (!result.properties) return;

                store.dispatch('InstanceProperties/SetSelection', {
                    instance: result,
                    fakeDbId: dbId,
                    selectedModelId: this.viewer.model?.id,
                });
            },
            console.log,
        );
    }

    resetRotationTool() {
        let rotationTool = this.viewer.toolController.getTool('Viewing.Rotate2D.Tool');

        rotationTool.clearSelection();
    }

    deleteSelectedObject() {
        if (this.selectedObjects.length) {
            this.selectedObjects.forEach((object) => {
                store.dispatch('RemoveManipulatedElement', object.name);
                this.viewer.impl.removeOverlay(this.customOverlayName, object);
                this.clonedObjects = this.clonedObjects.filter((obj) => obj !== object);
               

            });

            this.resetSelectionAndHoverStates();

            this.viewer.impl.invalidate(true);

            console.log('Selected objects deleted');
        }
    }
    deleteObject(object) {

        store.dispatch('RemoveManipulatedElement', object.name);
        this.viewer.impl.removeOverlay(this.customOverlayName, object);
        this.clonedObjects = this.clonedObjects.filter((obj) => obj !== object);

        this.resetObjectAndHoverStates(object);

        this.viewer.impl.invalidate(true);

        console.log('Selected objects deleted');
    }

    resetManipulatedElement(dbId) {
        this.translate2dTool.resetManipulatedElement(dbId);
    }

    deleteManipulatedElement(dbId) {
        let object = this.clonedObjects.find((obj) => obj.name == dbId);

        if (!object) return;

        this.viewer.impl.removeOverlay(this.customOverlayName, object);

        this.clonedObjects = this.clonedObjects.filter((obj) => obj !== object);

        this.viewer.impl.invalidate(true);

        console.log(`Manipulated element ${dbId} deleted`);
    }

    resetSelectionAndHoverStates() {
        if (this.selectedObjects.length) {
            this.selectedObjects.forEach((object) => {
                object.material.color.setHex(this.clonedObjectColor);
            });
            this.selectedObjects = [];
            this.clearControls();
        }
        if (this.hoveredObject) {
            this.hoveredObject.material.color.setHex(this.clonedObjectColor); // Reset the color of the previously hovered object
            this.hoveredObject = null;
        }
    }
    resetObjectAndHoverStates(object) {


        object.material.color.setHex(this.clonedObjectColor);

        this.clearControls();


    }

    startManipulatingElement() {
        if (!this.selectedObjects.length) return;

        if (this.selectedObjects.length == 1) {
            this.rotate2dTool.rotateElement();
        }

        this.createControls();

        this.viewer.impl.invalidate(true);
    }

    async moveElement(existingElement) {
        if (!existingElement) return;

        const dbId = parseInt(existingElement.dbId);

        const model = window.NOP_VIEWER.model;

        let worldPosition = new THREE.Vector3().copy(existingElement.position);

        let position = ViewerToolkit.worldToSheet(
            worldPosition,
            model,
            store.getters['VIEWER/TRANSFORM_MATRIX'],
        );
        if(!position){
            position = await this.translate2dTool.tryGetMappedSheetPositions(worldPosition);
        }
        this.translate2dTool.moveExistingElement(dbId, position);
    }

    async addClonedElement(manipulatedElement, ignoreUpdate = false) {
        const dbId = parseInt(manipulatedElement.dbId);
        const originalDbId = parseInt(manipulatedElement.originalDbId);
        const model = window.NOP_VIEWER.model;
        const objectId = model.reverseMapDbIdFor2D(originalDbId);

        let matchedBlock = await store.dispatch('GET_BLOCK_NODE_BY_SEARCH', {
            name: manipulatedElement.familyType,
            family: manipulatedElement.name,
        });

        if (!matchedBlock) return;

        // Clone

        const points = matchedBlock.geometryPoints;

        if (points.length === 0) {
            let blocksTree = store.getters.BLOCKS_TREE ?? [];

            let family = blocksTree.find((family) =>
                family.children.find((child) => child.dbId == originalDbId),
            );
            let type = family?.children?.find((child) => child.dbId == originalDbId);

            if (type) {
                points.push(...(type.geometryPoints ?? []));
            }
        }

        if (points.length === 0) {
            console.error('No points found');
            return;
        }

        const material = new THREE.LineBasicMaterial({ color: this.clonedObjectColor });
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const lineSegments = new THREE.LineSegments(geometry, material);

        lineSegments.name = dbId;

        // Move

        let worldPosition = new THREE.Vector3().copy(manipulatedElement.position);

        let position = ViewerToolkit.worldToSheet(
            worldPosition,
            model,
            store.getters['VIEWER/TRANSFORM_MATRIX'],
        );
        if (!position) {
              position = ViewerToolkit.map3DTo2D(
                  worldPosition,
                  this.translate2dTool.elements3dPoints,
                  this.translate2dTool.elements2dPoints,
              );
        }
        if (!position) {
            console.error('No position found');
            return;
        }

        let bbox = new THREE.Box3().setFromObject(lineSegments);

        let originalCenter = bbox.getCenter(new THREE.Vector3());

        let translationDiff = position.sub(originalCenter);

        this.applyTranslationToVertices(lineSegments, translationDiff);

        // Rotate

        if (manipulatedElement?.rotation) {
            this.rotate2dTool.rotateClonedElement(
                lineSegments,
                null,
                manipulatedElement.rotation.z,
                ignoreUpdate,
            );
        }

        // Update store

        this.clonedObjects.push(lineSegments);

        this.viewer.impl.addOverlay(this.customOverlayName, lineSegments);
        this.viewer.impl.invalidate(true);
    }

    insertClonedBlock(manipulatedElement, points, ignoreTimeout = false) {
        const dbId = parseInt(manipulatedElement.dbId);
        const model = window.NOP_VIEWER.model;

        const material = new THREE.LineBasicMaterial({ color: this.clonedObjectColor });
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const lineSegments = new THREE.LineSegments(geometry, material);

        lineSegments.name = dbId;

        // Move

        let worldPosition = new THREE.Vector3().copy(manipulatedElement.position);

        let position = ViewerToolkit.worldToSheet(
            worldPosition,
            model,
            store.getters['VIEWER/TRANSFORM_MATRIX'],
        );
        if (!position) {
              position = ViewerToolkit.map3DTo2D(
                  worldPosition,
                  this.translate2dTool.elements3dPoints,
                  this.translate2dTool.elements2dPoints,
              );
        }
        if (!position) {
            console.error('No position found');
            return;
        }

        let bbox = new THREE.Box3().setFromObject(lineSegments);

        let originalCenter = bbox.getCenter(new THREE.Vector3());

        let translationDiff = position.clone().sub(originalCenter);

        this.applyTranslationToVertices(lineSegments, translationDiff);

        // Rotate

        if (manipulatedElement?.rotation) {
            this.rotate2dTool.rotateClonedElement(
                lineSegments,
                null,
                manipulatedElement.rotation.z,
            );
        }

        // Update store

        this.clonedObjects.push(lineSegments);

        this.viewer.impl.addOverlay(this.customOverlayName, lineSegments);

        this.viewer.impl.invalidate(true);

        if (!ignoreTimeout) {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(() => {
                store.dispatch('SaveLocalModelChanges');
            }, 3000);
        } else {
            store.dispatch('SaveLocalModelChanges');
        }
        return lineSegments;
    }
    insertObjectBlock(lineSegments, ignoreTimeout = false) {
     
        // Update store

        this.clonedObjects.push(lineSegments);

        this.viewer.impl.addOverlay(this.customOverlayName, lineSegments);

        this.viewer.impl.invalidate(true);

        if (!ignoreTimeout) {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(() => {
                store.dispatch('SaveLocalModelChanges');
            }, 3000);
        } else {
            store.dispatch('SaveLocalModelChanges');
        }
        return lineSegments;
    }


    async cloneSelectedElement() {
        const selection = this.viewer.getSelection();

        if (selection.length === 0) return;

        const dbId = selection[0];

        if (!dbId) return;

        const objectId = this.viewer.model.reverseMapDbIdFor2D(dbId);
        const it = this.viewer.model.getData().instanceTree;

        const points = [];

        it.enumNodeFragments(
            dbId,
            (fragId) => {
                let renderProxy = this.viewer.impl.getRenderProxy(this.viewer.model, fragId);
                let vbr = new Autodesk.Viewing.Private.VertexBufferReader(renderProxy.geometry);

                let lines = [];

                vbr.enumGeomsForObject(objectId, {
                    onLineSegment: (x1, y1, x2, y2, vpId) => {
                        lines.push({ x1: x1 + 1, y1, x2: x2 + 1, y2 });
                    },
                });

                this.checkOverlayScene();

                lines.forEach((line) => {
                    points.push(new THREE.Vector3(line.x1, line.y1, 0));
                    points.push(new THREE.Vector3(line.x2, line.y2, 0));
                });
            },
            true,
        );

        if (points.length === 0) {
            console.error('No points found');
            return;
        }

        const material = new THREE.LineBasicMaterial({ color: this.clonedObjectColor });
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const lineSegments = new THREE.LineSegments(geometry, material);

        const manipulatedElementsIds = store.getters.MANIPULATED_ELEMENTS.map((el) => el.dbId);
        const maxDbId =
            manipulatedElementsIds.length == 0 ? 0 : Math.max(...manipulatedElementsIds);
        const nextDbId = maxDbId + 1;
        const bBox = new THREE.Box3().setFromPoints(points);
        const center = bBox.getCenter(new THREE.Vector3());
        let position = ViewerToolkit.sheetToWorld(
            this.viewer,
            center,
            this.viewer.model,
            store.getters['VIEWER/GLOBAL_OFFSET'],
        );
        if (!position) {
               position = ViewerToolkit.map2DTo3D(
                  center,
                  this.translate2dTool.elements2dPoints,
                  this.translate2dTool.elements3dPoints,
              );
          }
        if (!position) {
            toast.error('Viewport not found');
            return;
        }
        const position3d = await this.handleAddClonedElementIn3D(dbId);
        if (position3d) {
            position.z = position3d.z;
        }
        const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(
            this.viewer.model,
            dbId,
            store,
        );
        this.viewer.model.getProperties(dbId, (props) => {
            let typeName = props.properties.find((prop) => prop.displayName === 'Type Name');
            let manipulatedElement = new ManipulatedElement();
            manipulatedElement.viewType = '2d';
            manipulatedElement.viewId = window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
            manipulatedElement.manipulationType = ManipulationTypes.CLONED_ELEMENT;
            manipulatedElement.name = props.name;
            manipulatedElement.familyType = typeName?.displayValue;
            manipulatedElement.dbId = nextDbId;
            manipulatedElement.originalDbId = dbId;
            manipulatedElement.originalExternalId = props.externalId;
            manipulatedElement.modelId = this.viewer.model.getSeedUrn();
            manipulatedElement.position = position;
            manipulatedElement.originalPosition = position;
            manipulatedElement.rotation = new THREE.Vector3(0, 0, 0);
            manipulatedElement.viewGuids = _viewGuids;
            store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);
            store.dispatch('SaveLocalModelChanges');
        });

        lineSegments.name = nextDbId;

        this.clonedObjects.push(lineSegments);

        this.viewer.impl.addOverlay(this.customOverlayName, lineSegments);
        this.viewer.impl.invalidate(true);

        setTimeout(() => {
            this.viewer.clearSelection();
            this.selectedObjects = [lineSegments];
            lineSegments.material.color.setHex(this.selectedObjectColor);
            this.startManipulatingElement();
            this.viewer.impl.invalidate(true);
        }, 10);
    }

    checkOverlayScene() {
        if (!this.viewer?.impl?.overlayScenes[this.customOverlayName]) {
            this.viewer.impl.createOverlayScene(this.customOverlayName);
        }
    }

    removeOverlayScene() {
        if (this.viewer?.impl?.overlayScenes[this.customOverlayName]) {
            this.viewer.impl.removeOverlayScene(this.customOverlayName);
        }
    }
    async handleAddClonedElementIn3D(dbId) {
        const parallelViewer = window.parallel3dViewer;

        for (let i = 0; i < store.getters.D3ViewItems.length; i++) {
            const guid = store.getters.D3ViewItems[i].data.guid;
            const position = await this.getPositionIn3D(parallelViewer, guid, dbId);
            if (position) return position;
        }
    }
    getPositionIn3D(parallelViewer, viewId, dbId) {
        return new Promise((resolve, reject) => {
            let root = parallelViewer.model.getDocumentNode().getRootNode();

            let views = root.search({ guid: viewId });
            if (!views.length) return;

            let view = views[0];
            const doc = parallelViewer.model.getDocumentNode().getRootNode().lmvDocument;
            let callback = (event) => {
                const model = event.model;

                if (model.getInstanceTree().getNodeName(dbId)) {
                    let it = model.getInstanceTree();

                    let fragList = model.getFragmentList();

                    let fragIds = [];

                    it.enumNodeFragments(dbId, (fragId) => {
                        fragIds.push(fragId);
                    });

                    let nodebBox = getWorldBoundingBox(fragIds, fragList);

                    let center = nodebBox.getCenter();

                    resolve(center);
                }

                parallelViewer.removeEventListener(
                    Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                    callback,
                );
            };

            parallelViewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, callback);
            parallelViewer.loadDocumentNode(doc, view);
        });
    }
}

// Autodesk.Viewing.theExtensionManager.registerExtension(ElementClone2D.ExtensionId, ElementClone2D);
