<template>
    <div>
        <div style="margin-left: 50px" v-if="markUpState && stateInput" id="markupTools">
            <v-card theme="dark">
                <v-layout>
                    <v-list density="compact" nav>
                        <v-list-item
                            v-for="tool of markupTools"
                            :key="tool.name"
                            @click="markup(tool.name)"
                            style="text-align: center">
                            <i :class="tool.icon"></i>
                        </v-list-item>
                        <v-list-item
                            v-for="markup of StampMarkup"
                            :key="markup.name"
                            @click="startStamp(markup)"
                            style="text-align: center">
                            <i class="fa-solid fa-stamp">
                               <v-tooltip activator="parent" location="bottom">{{markup.name}}</v-tooltip>
                            </i>
                            <!-- <i style="width:50px;height:50px" v-html="span(markup.markupsPersist)"></i> -->
                        </v-list-item>
                    </v-list>

                    <v-main style="height: 300px"></v-main>
                </v-layout>
            </v-card>
        </div>
    
        <div v-if="markUpState && stateInput" id="styleTool">
            <v-card id="stylesCont" title="Styles" theme="dark">
                <div>
                    <label v-if="!textSelected" style="float: left; margin-left: 2%"
                        >Border Thickness</label
                    >
                    <label v-if="textSelected" style="float: left; margin-left: 2%"
                        >Font Size</label
                    >

                    <div>
                        <br />
                        <v-slider
                            :min="1.5"
                            :max="20"
                            model-value="5"
                            v-model="sliderValue"
                            color="white"
                            style="padding: 2%"></v-slider>
                    </div>
                </div>
                <div v-if="!NoFill">
                    <label style="float: left; margin-left: 2%">Opacity</label>

                    <div>
                        <br />
                        <v-slider
                            v-model="opacityValue"
                            color="white"
                            style="padding: 2%"></v-slider>
                    </div>
                </div>
                <div>
                    <div style="height: 30px; width: 100%">
                        <label style="float: left; margin-left: 2%">Border Color</label>
                        <div
                            @click="borderToggle = !borderToggle"
                            class="chooseColor"
                            v-bind:style="{ backgroundColor: borderColor }"></div>
                    </div>
                    <br />
                    <div v-if="borderToggle && stateInput">
                        <v-color-picker
                            width="auto"
                            v-model="borderColor"
                            hide-inputs
                            hide-canvas
                            mode="rgb"
                            style="margin: auto; padding: 2%"></v-color-picker>
                        <div class="pre-color mt-3">
                            <v-row class="pa-3">
                                <v-col v-for="item in colors" :key="item" cols="1">
                                    <div
                                        @click="changeBorder(item)"
                                        class="bg-surface-variant rounded-circle"
                                        :style="{ backgroundColor: item + '!important' }"
                                        style="height: 15px; width: 15px"></div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
                <div v-if="!NoFill" style="margin-top: 2%">
                    <div style="height: 30px; width: 100%">
                        <label style="float: left; margin-left: 2%">Fill Color</label>
                        <div
                            @click="fillToggle = !fillToggle"
                            class="chooseColor"
                            v-bind:style="{ backgroundColor: fillColor }"></div>
                    </div>

                    <br />
                    <div v-if="fillToggle">
                        <v-color-picker
                            width="auto"
                            v-model="fillColor"
                            hide-inputs
                            hide-canvas
                            v-if="fillToggle"
                            mode="rgb"
                            style="margin: auto; padding: 2%"></v-color-picker>
                        <div class="pre-color mt-3">
                            <v-row class="pa-3">
                                <v-col v-for="item in colors" :key="item" cols="1">
                                    <div
                                        @click="changeFill(item)"
                                        class="bg-surface-variant rounded-circle"
                                        :style="{ backgroundColor: item + '!important' }"
                                        style="height: 15px; width: 15px"></div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
        <div v-if="markUpState && stateInput" class="editsTool">
            <v-card theme="dark">
                <i @click="undo()" class="fa-solid fa-rotate-left topbar">
                    <v-tooltip activator="parent" location="bottom">undo</v-tooltip>
                </i>
                <i @click="redo()" class="fa-solid fa-rotate-right topbar">
                    <v-tooltip activator="parent" location="bottom">redo</v-tooltip>
                </i>
                <i @click="save()" v-if="isNewMarkup" class="fa-solid fa-floppy-disk topbar">
                    <v-tooltip activator="parent" location="bottom">save</v-tooltip>
                </i>
                <i @click="saveStamp()" v-if="isNewMarkup" class="fa-solid fa-stamp">
                    <v-tooltip activator="parent" location="bottom">save stamp</v-tooltip>
                </i>
                <i @click="snapper = !snapper" v-if="!openMarkupImg && is2d()" :class="snapIcon">
                    <v-tooltip activator="parent" location="bottom">snap</v-tooltip>
                </i>
                <i @click="exit()" class="fa-solid fa-xmark topbar">
                    <v-tooltip activator="parent" location="bottom">exit</v-tooltip>
                </i>
            </v-card>
        </div>

        <v-card class="editsTool hideTool" v-if="hideAll" theme="dark">
            <i
                @click="exit()"
                style="font-size: 15px; color: white"
                class="fa-regular fa-eye-slash">
                <v-tooltip activator="parent" location="bottom">hide</v-tooltip>
            </i>
        </v-card>
    </div>
</template>
<script>
    export default {
        props: {
            stateInput: null,
        },
        data() {
            return {
                sliderValue: 5,
                opacityValue: 0,
                fillColor: 'ff0000',
                borderColor: 'ff0000',
                textSelected: false,
                NoFill: false,
                fillToggle: false,
                borderToggle: false,
                snapper: false,
                snapIcon: 'fa-regular fa-square topbar',
                markupTools: [
                    { name: 'arrow', icon: 'fa-solid fa-arrow-up' },
                    { name: 'text', icon: 'fa-solid fa-text-height' },
                    { name: 'circle', icon: 'fa-regular fa-circle' },
                    { name: 'rect', icon: 'fa-regular fa-square' },
                    { name: 'cloud', icon: 'fa-solid fa-square' },
                    { name: 'freehand', icon: 'fa-solid fa-pencil' },
                    { name: 'polygon', icon: 'fa-solid fa-draw-polygon' },
                    { name: 'line', icon: 'fa-sharp fa-solid fa-grip-lines' },
                    { name: 'Highlight', icon: 'fa-solid fa-highlighter' },
                    { name: 'delete', icon: 'fa-solid fa-trash' },
                ],
                markupFunc: [
                    { name: 'save()', icon: 'fa-solid fa-floppy-disk' },
                    { name: 'load()', icon: 'fa-solid fa-upload' },
                ],
                colors: [
                    '#ff80ed',
                    '#065535',
                    '#000000',
                    '#133337',
                    '#ffc0cb',
                    '#ffffff',
                    '#ffe4e1',
                    '#008080',
                    '#ff0000',
                    '#e6e6fa',
                    '#ffd700',
                    '#00ffff',
                    '#ffa500',
                    '#0000ff',
                    '#c6e2ff',
                    '#40e0d0',
                    '#ff7373',
                    '#b0e0e6',
                    '#d3ffce',
                    '#f0f8ff',
                    '#666666',
                    '#faebd7',
                    ' #bada55',
                    '#fa8072',
                ],
            };
        },

        methods: {
            markup(type) {
                if (type == 'text') {
                    this.textSelected = true;
                } else if (
                    type == 'arrow' ||
                    type == 'Highlight' ||
                    type == 'line' ||
                    type == 'freehand'
                ) {
                    this.NoFill = true;
                } else {
                    this.textSelected = false;
                    this.NoFill = false;
                }
                this.$store.dispatch('MARKUPEDITOR/changeMarkupMode', { markup: type });
            },
            startStamp(item) {
                this.$store.dispatch('MARKUPEDITOR/startStamp', { markup: item });
            },
            save() {
                this.$store.commit('MARKUPEDITOR/SET_IS_STAMP', false);
                this.$store.dispatch('MARKUPEDITOR/SaveAndHideMarkups');
            },
            saveStamp() {
                this.$store.commit('MARKUPEDITOR/SET_IS_STAMP', true);
                this.$store.dispatch('MARKUPEDITOR/SaveAndHideMarkups');
            },
            load() {
                this.$store.dispatch('MARKUPEDITOR/loadMarkups');
            },
            exit() {
                this.$store.dispatch('MARKUPEDITOR/ExitMarkup');
                if (this.hideAll) {
                    this.$store.dispatch('MARKUPEDITOR/setHideAll', false);
                }
            },
            undo() {
                this.$store.dispatch('MARKUPEDITOR/undoMarkup');
            },
            redo() {
                this.$store.dispatch('MARKUPEDITOR/redoMarkup');
            },
            changeFill(item) {
                this.fillColor = item;
            },
            changeBorder(item) {
                this.borderColor = item;
            },
            is2d() {
                return !!window.NOP_VIEWER?.model?.is2d();
            },
            span(text) {
                return text;
            },
        },
        watch: {
            sliderValue(value) {
                this.$store.dispatch('MARKUPEDITOR/setSelectedItemBorderWidth', {
                    value: value,
                });
            },
            opacityValue(value) {
                this.$store.dispatch('MARKUPEDITOR/setSelectedItemOpacity', {
                    value: value / 100,
                });
            },
            borderColor(value) {
                this.$store.dispatch('MARKUPEDITOR/setSelectedItemBorderColor', { value: value });
            },
            fillColor(value) {
                this.$store.dispatch('MARKUPEDITOR/setSelectedItemFillColor', { value: value });
            },

            snapper(value) {
                if (value) {
                    this.snapIcon = 'fa-solid fa-square topbar';
                    this.$store.dispatch('MARKUPEDITOR/setSnapOn');
                } else {
                    this.snapIcon = 'fa-regular fa-square topbar';
                    this.$store.dispatch('MARKUPEDITOR/setSnapOff');
                }
            },
            markUpState(value) {
                if (value) {
                    this.sliderValue = 5;
                    this.opacityValue = 0;
                    this.fillColor = 'ff0000';
                    this.borderColor = 'ff0000';
                }
            },
            toolsDisabled(value) {
                if (value) {
                    this.borderToggle = false;
                    this.fillToggle = false;
                }
            },
            selectedSliderValue(value) {
                this.sliderValue = value;
            },
        },
        mounted() {
            this.$store.dispatch('MARKUPDATA/GetStampMarkups');
        },
        computed: {
            markUpState: {
                get: function () {
                    return this.$store.getters['MARKUPEDITOR/MARKUP_STATE'];
                },
            },
            isNewMarkup() {
                return this.$store.getters['MARKUPEDITOR/IS_NEW_MARKUP'];
            },
            currentMarkup() {
                return this.$store.getters['MARKUPEDITOR/CURRENT_MARK'];
            },
            AttachId() {
                return this.$store.getters['MARKUPEDITOR/ATTACH_ID'];
            },
            IsAttach() {
                return this.$store.getters['MARKUPEDITOR/IS_ATTACH'];
            },
            selectedAttach() {
                return this.$store.getters['CameraAttachementStore/SELECTED_CAMERA_ELEMENT'];
            },
            openMarkupImg() {
                return this.$store.getters['MARKUPDATA/OPEN_MARKUP_IMG'];
            },
            hideAll() {
                return this.$store.getters['MARKUPEDITOR/HIDE_ALL'];
            },
            toolsDisabled() {
                return this.$store.getters['MARKUPEDITOR/TOOLS_DISABLED'];
            },
            selectedSliderValue() {
                return this.$store.getters['MARKUPEDITOR/SELECTED_SLIDER_VALUE'];
            },
            StampMarkup() {
                return this.$store.getters['MARKUPDATA/STAMP_MARKUPS'];
            },
        },
    };
</script>
<style lang="css">
    #markupTools {
        position: absolute;
        z-index: 5;
        top: 1%;
        right: 3px;
    }
    .editsTool {
        position: absolute;
        z-index: 5;
        top: 2%;
        left: 45%;
        /* width: 140px; */
        text-align: center;
    }
    .hideTool {
        align-content: center;
        width: 50px;
        height: 25px;
        text-align: center;
        cursor: pointer;
    }
    #styleTool {
        position: absolute;
        z-index: 5;
        top: 1%;
        right: 54px;
        width: 21%;
        text-align: center;
        transform: translateX(0%);
    }
    .topbar {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 7%;
        padding-bottom: 7%;
        width: 31px;
    }
    #stylesCont {
        padding-left: 11px;
        padding-right: 11px;
    }
    .chooseColor {
        background-color: #ff0000;
        cursor: pointer;
        width: 30%;
        height: 30px;
        float: right;
        position: absolute;
        right: 12%;
        z-index: 2;
    }
</style>
