<template>
    <v-container transition="fade-transition" :style="{ top: active ? '1rem' : '-100rem' }"
        class="translation-controls bg-grey-darken-4">
        <v-row justify="start" class="translation-controls-title bg-grey-darken-2">
            <v-card-title class="text-h6 text-center px-2 py-0">Translation Controls</v-card-title>
        </v-row>
        <v-row justify="center" class="align-center justify-center px-sm-1">
            <v-col :cols="colWidth"  class="py-1 px-sm-1">
                <v-text-field v-model="xValue" type="number" step="0.01" height="30" hide-details density="compact"
                    variant="outlined" prepend-inner-icon="fa-solid fa-x" class="custom-input"></v-text-field>
            </v-col>
            <v-col :cols="colWidth"  class="py-1 px-sm-1">
                <v-text-field v-model="yValue" type="number" step="0.01" height="30" hide-details density="compact"
                    variant="outlined" prepend-inner-icon="fa-solid fa-y" class="custom-input"></v-text-field>
            </v-col>
            <v-col :cols="colWidth"  class="py-1 px-sm-1" v-if="!is2d">
                <v-text-field v-model="zValue" type="number" step="0.01" height="30" hide-details density="compact"
                    variant="outlined" prepend-inner-icon="fa-solid fa-z" class="custom-input"></v-text-field>
            </v-col>
            <v-col :cols="colWidth"  class="py-1 px-sm-1">
                <v-btn class="apply-btn" density="compact" height="40" color="success" variant="tonal"
                    @click="applyChanges">Apply</v-btn>
            </v-col>
        </v-row>
        <v-row justify="center" class="align-center justify-center px-sm-1">
            <v-col cols="4" lg="4" class="py-1 px-sm-1 ">
                <v-text-field v-model="degree" type="number" height="30" hide-details density="compact"
                    variant="outlined" prepend-inner-icon="fa-solid fa-theta" class="custom-input"></v-text-field>
            </v-col>
            <v-col cols="4" lg="4" class="py-1 px-sm-1">
                <v-btn @click="rotateCW" class="apply-btn" density="compact" height="40" color="success"
                    variant="tonal"><i class="fas fa-redo fa-xl"></i></v-btn>
            </v-col>
            <v-col cols="4" lg="4" class="py-1 px-sm-1">
                <v-btn @click="rotateCCW" class="apply-btn" density="compact" height="40" color="success"
                    variant="tonal"><i class="fas fa-undo fa-xl"></i></v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ViewerToolkit from '../../../extensions/Viewer.Toolkit/Viewer.Toolkit';
import { ManipulatedElement, ManipulationTypes } from "@/models/ForgeModels";
    export default {
        data() {
            return {
                xValue: 0.0,
                yValue: 0.0,
                zValue: 0.0,
                degree: 90.0,
                saveTimeout: null,
                is2d: false
            };
        },

        methods: {
            applyChanges() {

                let point = new window.THREE.Vector3(
                    this.startPoint.x + parseFloat(this.xValue ?? 0),
                    this.startPoint.y + parseFloat(this.yValue ?? 0),
                    this.startPoint.z + parseFloat(this.zValue ?? 0),
                );

                this.$store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', point);

                if (window.NOP_VIEWER?.model?.is2d()){
                    if (this.translate2dTool?.transformControlTx?.visible) {
                        this.translate2dTool.forceUpdate();
                    } else if (this.clone2dTool?.transformControlTx?.visible) {
                        this.clone2dTool.forceUpdate();
                    }
                }else{
                    this.translate3dTool.forceUpdate();
                }
            },
            updateValues() {
                this.xValue = (this.currentPoint.x - this.startPoint.x).toFixed(2);
                this.yValue = (this.currentPoint.y - this.startPoint.y).toFixed(2);
                this.zValue = (this.currentPoint.z - this.startPoint.z).toFixed(2);
            },
            async rotateCW(){               
                const angle = parseFloat(this.degree ?? 0)

                let selection = window.NOP_VIEWER.getAggregateSelection();

                if (window.NOP_VIEWER?.model?.is2d()) {
                    if (!selection?.length) {
                        this.clone2dTool.rotateElementWithAngle(-angle, true)
                    } else {
                        const dbId = selection[0].selection[0]
                        let center = ViewerToolkit.worldToSheet(this.currentPoint, selection[0].model, this.$store.getters['VIEWER/TRANSFORM_MATRIX']);
                        if(!center){
                            center = await this.translate2dTool.tryGetMappedSheetPositions(this.currentPoint);
                        }
                        let angleRad = window.THREE.Math.degToRad(angle);
                        this.rotate2dTool.rotateModelElement(dbId, 'Z', - angleRad, center)
                        this.updateManipulatedElementRotation(dbId, new window.THREE.Vector3(0, 0, - angleRad))
                    }
               }else{
                    this.rotate3d(selection, window.THREE.Math.degToRad(-angle))

                    if (this.saveTimeout) {
                        clearTimeout(this.saveTimeout);
                    }

                    this.saveTimeout = setTimeout(() => {
                        this.$store.dispatch('SaveLocalModelChanges');
                    }, 500);
               }
            },
            async rotateCCW() {
                const angle = parseFloat(this.degree ?? 0)
                let selection = window.NOP_VIEWER.getAggregateSelection();
                if (window.NOP_VIEWER?.model?.is2d()) {
                    if (!selection?.length) {
                        this.clone2dTool.rotateElementWithAngle(angle, true)
                    } else {
                        const dbId = selection[0].selection[0]
                        let center = ViewerToolkit.worldToSheet(this.currentPoint, selection[0].model, this.$store.getters['VIEWER/TRANSFORM_MATRIX']);
                        if (!center) {
                            center = await this.translate2dTool.tryGetMappedSheetPositions(this.currentPoint);
                        }
                        let angleRad = window.THREE.Math.degToRad(angle); 
                        this.rotate2dTool.rotateModelElement(dbId, 'Z', angleRad, center) 
                        await this.updateManipulatedElementRotation(dbId, new window.THREE.Vector3(0, 0, angleRad))
                    }
                }
                else{
                    this.rotate3d(selection, window.THREE.Math.degToRad(angle))

                    if (this.saveTimeout) {
                        clearTimeout(this.saveTimeout);
                    }

                    this.saveTimeout = setTimeout(() => {
                        this.$store.dispatch('SaveLocalModelChanges');
                    }, 500);
                }
            },
            rotate3d(selection, angle){
                var axis = new window.THREE.Vector3(0, 0, 1);
                let isSceneBuilder = selection[0].model.myData?.isSceneBuilder;
                const it = selection[0].model.getInstanceTree();
                let frags = []
                it.enumNodeFragments(
                    selection[0].selection[0],
                    (fragId) => {
                        frags.push(fragId);
                    },
                    true,
                );
                if (isSceneBuilder) {
                    this.rotate3dTool.rotateClonedElement(
                        {
                            model: selection[0].model,
                            dbIdArray: selection[0].selection,
                            fragIdsArray: frags
                        },
                        axis,
                        angle,
                        this.currentPoint,
                    );
                } else {
                    this.rotate3dTool.rotateFragments(
                        selection[0].model,
                        frags,
                        axis,
                        angle,
                        this.currentPoint,
                    );
                }

                window.NOP_VIEWER.impl.sceneUpdated(true);
            },
            async updateManipulatedElementRotation(dbId, _rotation){
                let manipulatedElement = this.$store.getters.MANIPULATED_ELEMENTS.find(element => element.dbId == dbId &&
                 element.manipulationType == ManipulationTypes.MANIPULATE_EXISTING);

                if (!manipulatedElement) {
                    const pos = this.$store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];
                    
                    const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(window.NOP_VIEWER.model, dbId, this.$store);
                    
                    window.NOP_VIEWER.getProperties(dbId, (props) => {
                        manipulatedElement = new ManipulatedElement();
                        manipulatedElement.viewType = '2d';
                        manipulatedElement.viewId = window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                        manipulatedElement.manipulationType = ManipulationTypes.MANIPULATE_EXISTING;
                        manipulatedElement.name = props.name;
                        manipulatedElement.modelId = window.NOP_VIEWER.model.getData().urn;
                        manipulatedElement.dbId = dbId;
                        manipulatedElement.originalDbId = dbId;
                        manipulatedElement.originalExternalId = props.externalId;
                        manipulatedElement.position = pos.clone();
                        manipulatedElement.originalPosition = pos.clone();
                        manipulatedElement.rotation = _rotation;
                        manipulatedElement.viewGuids = _viewGuids;

                        this.$store.commit("ADD_MANIPULATED_ELEMENT", manipulatedElement);
                        this.$store.dispatch('SaveLocalModelChanges')
                    });
                } else {
                    if (!manipulatedElement.rotation) manipulatedElement.rotation = _rotation.clone()
                    else manipulatedElement.rotation.z += _rotation.z;
                    this.$store.dispatch('SaveLocalModelChanges')
                }
              

              
            }
        },
        computed: {
            currentPoint: {
                get() {
                    return this.$store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];
                },
                set(value) {
                    this.$store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', value);
                },
            },
            startPoint: {
                get() {
                    return this.$store.getters['VIEWER/START_MANIPULATION_POSITION'];
                },
            },
            active: {
                get() {
                    return this.currentPoint && this.startPoint;
                },
            },
            rotate2dTool: function () {
                return window.NOP_VIEWER.toolController.getTool('Viewing.Rotate2D.Tool');
            },
            translate2dTool: function () {
                return window.NOP_VIEWER.toolController.getTool('Viewing.Translate2DTool.Tool');
            },
            translate3dTool: function () {
                return window.NOP_VIEWER.toolController.getTool('Viewing.Transform.Tool');
            },
            rotate3dTool: function () {
                return window.NOP_VIEWER.toolController.getTool('Viewing.Rotate.Tool');
            },
            clone2dTool: function () {
                return window.NOP_VIEWER.toolController.getTool('Viewing.Clone2D.Tool');
            },
            is2D: function () {
                    return this.is2d;
                },
            colWidth: function(){
                if(this.is2d) return 4;
                return 3
            }    
            
        },
        created() {
            if (this.active) {
                this.xValue = this.currentPoint.x - this.startPoint.x;
                this.yValue = this.currentPoint.y - this.startPoint.y;
                this.zValue = this.currentPoint.z - this.startPoint.z;
                this.is2d = !!window.NOP_VIEWER?.model?.is2d()
            }
        },
        watch: {
            currentPoint(point) {
                if (point) {
                    this.xValue = (this.currentPoint.x - this.startPoint.x).toFixed(2);
                    this.yValue = (this.currentPoint.y - this.startPoint.y).toFixed(2);
                    this.zValue = (this.currentPoint.z - this.startPoint.z).toFixed(2);
                    this.is2d = !!window.NOP_VIEWER?.model?.is2d()
                }
            },
          is2D: {
                handler: function (is2d) {
                    if (is2d) {
                        this.zValue = 0;
                    }
                },
                immediate: true,
                deep: true
            },
        },
    };
</script>

<style>
    .translation-controls {
        position: absolute;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: max(25%, 420px);
        border-radius: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: top 0.3s ease-in-out;
        z-index: 1000;
        padding: 1rem;
    }

    .translation-controls-title {
        border-radius: 0.5rem 0.5rem 0 0;
        padding: 0.5rem;
    }

    .apply-btn {
        width: 100%;
        height: 100%;
    }
</style>
