<template lang="html">
    <v-dialog v-model="active" width="600" persistent theme="dark">
        <v-card>
            <v-card-title class="text-h5 d-flex align-center">
                Compare documents
                <v-spacer></v-spacer>
                <v-btn icon @click="close" variant="plain">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
				<v-alert v-if="error" type="error" density="compact">{{ error }}</v-alert>
                <v-row>
                    <v-col cols="6">
                        <div class="text-subtitle-1 d-flex align-center justify-space-between">
                            <span>Document A</span>
                            <!-- <v-btn color="primary" variant="plain">Change</v-btn> -->
                        </div>
                        <v-card outlined class="mt-2" elevation="4">
                            <v-card-title>{{ documentTitleA }}</v-card-title>
                            <v-img :src="thumbnailA" height="200" contain></v-img>
                            <v-card-text>
                                <v-select
                                    class="mb-3"
                                    hide-details
                                    color="primary"
                                    label="Version"
                                    :loading="loadingVersionsA"
                                    :items="versionsA"
                                    v-model="versionA"
                                    density="compact"
                                    variant="outlined"></v-select>
                                <v-select
                                    hide-details
                                    color="primary"
                                    :loading="loadingViewsA"
                                    label="Sheet / View"
                                    :items="viewsA"
                                    v-model="viewA"
                                    density="compact"
                                    variant="outlined">
									<template v-slot:item="{ props, item }">
										<v-list-item v-bind="props" :subtitle="item.value.role">
										</v-list-item>
									</template>	
								</v-select>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <div class="text-subtitle-1 d-flex align-center justify-space-between">
                            <span>Document B</span>
                            <!-- <v-btn color="primary" variant="plain">Change</v-btn> -->
                        </div>
                        <v-card outlined class="mt-2" elevation="4">
                            <v-card-title>{{ documentTitleB }}</v-card-title>
                            <v-img :src="thumbnailB" height="200" contain></v-img>
                            <v-card-text>
                                <v-select
                                    class="mb-3"
                                    hide-details
                                    color="primary"
                                    label="Version"
                                    :loading="loadingVersionsB"
                                    :items="versionsB"
                                    v-model="versionB"
                                    density="compact"
                                    variant="outlined"></v-select>
                                <v-select
                                    hide-details
                                    color="primary"
                                    label="Sheet / View"
                                    :loading="loadingViewsB"
                                    :items="viewsB"
                                    v-model="viewB"
                                    density="compact"
                                    variant="outlined">
									<template v-slot:item="{ props, item }">
										<v-list-item v-bind="props" :subtitle="item.value.role">
										</v-list-item>
									</template>
								</v-select>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="mx-5 mb-2">
                <v-spacer></v-spacer>
                <v-btn color="error" @click="close">Close</v-btn>
                <v-btn variant="flat" color="primary" :disabled="error" width="100px" :loading="loading" @click="startCompare">Compare</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import CompareToolExtension from '@/extensions/CompareTool/CompareToolExtension';

    export default {
        data() {
            return {
				error: null,
                caches: {},

                loading: false,

                documentA: null,
                documentB: null,
                documentTitleA: null,
                documentTitleB: null,
                versionsA: [],
                versionsB: [],
                versionA: null,
                versionB: null,
				viewsA: [],
				viewsB: [],
                viewA: null,
                viewB: null,
                thumbnailA: null,
                thumbnailB: null,
                loadingVersionsA: false,
                loadingVersionsB: false,
                loadingViewsA: false,
                loadingViewsB: false,
            };
        },
        methods: {
            openDialog() {
                this.documentTitleA = this.currentModel.modelName;
                this.versionsA = this.currentModelVersions.map((v) => {
                    return {
                        title: v.name.split(':')[0],
                        value: v,
                    };
                });
                this.versionA = this.versionsA[0].value;

                this.documentTitleB = this.currentModel.modelName;
                this.versionsB = this.currentModelVersions.map((v) => {
                    return {
                        title: v.name.split(':')[0],
                        value: v,
                    };
                });
                this.versionB = this.versionsB[0].value;
            },
            async startCompare() {
                if (this.error) return;
                if (this.viewA.guid == this.viewB.guid && this.versionA.urn == this.versionB.urn) {
                    this.error = 'Cannot compare the same view';
                    return;
                }
                this.loading = true;
                await this.compareExtension.startCompare(this.viewA, this.viewB);
                this.loading = false;
                this.active = false;
            },
			checkViewsCompatibility() {
				if (this.viewA && this.viewB) {
					if (this.viewA.role == this.viewB.role) {
						if (this.viewA.role != '3d') {
							this.error = null;
						} else {
							if (this.versionA.itemId == this.versionB.itemId) {
								this.error = null;
							} else {
								this.error = 'Views are not compatible, compare 3d only compare versions of the same model';
							}
						}
					} else {
						this.error = 'Views are not compatible, can only compare views of the same type';
					}
				}
			},
            close() {
                this.loading = false;
                this.active = false;
            },
        },
        mounted() {},
        computed: {
            active: {
                get() {
                    return this.$store.getters['COMPARE_TOOL/SHOW_COMPARE_POPUP'];
                },
                set(value) {
                    this.$store.commit('COMPARE_TOOL/SET_SHOW_COMPARE_POPUP', value);
                },
            },
            compareExtension() {
                return window.NOP_VIEWER.getExtension(CompareToolExtension.extensionId);
            },
            currentModelVersions() {
                return this.$store.getters['ALL_VERSIONS'];
            },
            currentModel() {
                return this.$store.getters['MODELS/CURRENT_MODEL_DATA'];
            },
        },
        watch: {
            active(value) {
                if (value) {
                    this.openDialog();
                }
            },
            versionA(value) {
				if (value == null) return;
				if (this.caches[value.urn]) {
					this.viewsA = this.caches[value.urn];
					this.viewA = this.caches[value.urn][0];
				} else {
					this.loadingViewsA = true;
					this.$store.dispatch('GetViewsAsSheets', value.urn).then((views) => {
						views = views.map((v) => {
							return {
								title: v.name,
								value: v,
							};
						});

						this.caches[value.urn] = views;
						this.viewsA = views;
						this.loadingViewsA = false;
						this.viewA = views[0].value;
					});
				}
            },
            versionB(value) {
                if (value == null) return;
				if (this.caches[value.urn]) {
					this.viewsB = this.caches[value.urn];
					this.viewB = this.caches[value.urn][0];
				} else {
					this.loadingViewsB = true;
					this.$store.dispatch('GetViewsAsSheets', value.urn).then((views) => {
						views = views.map((v) => {
							return {
								title: v.name,
								value: v,
							};
						});
						
						this.caches[value.urn] = views;
						this.viewsB = views;
						this.loadingViewsB = false;
						this.viewB = views[0].value;
					});
				}
            },
			viewA() {
				this.checkViewsCompatibility();
                this.$store.dispatch('GetViewThumbnail', { urn: this.viewA.urn, viewId: this.viewA.guid }).then((thumbnail) => {
                    this.thumbnailA = thumbnail;
                });
			},
			viewB() {
				this.checkViewsCompatibility();
                this.$store.dispatch('GetViewThumbnail', { urn: this.viewB.urn, viewId: this.viewB.guid }).then((thumbnail) => {
                    this.thumbnailB = thumbnail;
                });
			}
        },
    };
</script>
<style lang="scss"></style>
