<template lang="html">
    <div class="viewer-page" :class="{'compare-mode': isCompareMode}">
        <v-sheet transition="fade-transition" class="status-bar elevation-2" theme="dark" v-if="selectedElementName">
            <v-icon class="mr-2">mdi-information</v-icon>
            <span class="text-center">{{ selectedElementName }}</span>
        </v-sheet>
        <!-- <touch-controls v-if="isTouchDevice"></touch-controls> -->
        <translation-controls ref="translationControls"></translation-controls>
        <MarkUp :isActive="true" />

        <div v-if="!!viewer" class="context-button" @click="openContextMenu">
            <i class="fa-solid fa-gear"></i>
        </div>

        <div class="mode-button ml-6" variant="text" theme="dark">
            <v-chip border color="#212121">
                {{ selectedItem }}
            </v-chip>
            <!-- <v-btn color="#212121"> {{ currentColorMode }}</v-btn> -->
            <v-menu activator="parent" location="end" theme="dark">
                <v-list>
                    <v-list-item @click="setDefaultColor(true)" :active="currentColorMode == 'Default Colors'">
                        <v-chip border class="subtitle"> Default Colors </v-chip>
                    </v-list-item>
                    <v-list-item @click="setDefaultColor(false)" :active="currentColorMode == 'Show Changes'">
                        <v-chip border class="subtitle"> Show Changes </v-chip>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :active="selectedItem == item" @click="applyColors(item)" v-for="item in templates"
                        :key="item.name"><v-chip border class="subtitle"> {{ item.name }} </v-chip></v-list-item>
                </v-list>
            </v-menu>
        </div>
        <div class="recent-custom" >
            <recent-views ></recent-views>
        </div>
       
        <div id="parallel3dViewer"></div>
        <div id="parallelForgeViewer"></div>
        <div id="forgeViewer">
            <MarkupEditors :stateInput="stateInput" />

            <AttachmentMarkup :img="markupAttachImg" v-if="openMarkupImg" />
        </div>
        <loader-view></loader-view>
        <CompareDocumentsPopup ref="comparePopup"></CompareDocumentsPopup>
    </div>
</template>
<script>
    /* eslint-disable vue/no-unused-components */
    import TouchControls from '../TouchControls/TouchControls.vue';
    import LoaderView from '../LoaderView.vue';
    import { isTouchDevice } from '@/services/compat';
    import TranslationControls from '@Home/components/TranslationControls.vue';
    import MarkUp from './../../features/Markups/components/MarkUp.vue';
    import MarkupEditors from './../../features/Markups/components/MarkupEditors.vue';
    import { $base64ToUTF8 } from '../../utils';
    import AttachmentMarkup from './../../features/Markups/components/AttachmentMarkup.vue';
    import { mapActions } from 'vuex';
    import { UpdateForgeElementsColors } from '@/utils/forgeElementsColors';
    import  RecentViews  from './../../components/RecentViews.vue';
    import CompareDocumentsPopup from '@/features/CompareDocuments/components/compareDocumentsPopup.vue';

    export default {
        components: {
            TouchControls,
            MarkUp,
            MarkupEditors,
            AttachmentMarkup,
            TranslationControls,
            LoaderView, 
            RecentViews,
            CompareDocumentsPopup,
        },
        props: {
            markupId: null, 
            
        },
        data() {
            return {
                myObjectUrn:
                    'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6aGktYnVja2V0L3JtZWFkdmFuY2Vkc2FtcGxlcHJvamVjdC5ydnQ',
                drawer: true,
                rail: true,
                selectedItem: 'Default Colors',
                
            };
        },

        methods: {
            ...mapActions(['OpenViewWithId']),
            openContextMenu(e) {
                window.NOP_VIEWER?.contextMenu.show(e);
            },
            initializeViewer() {
                const modelId = this.$route.params.id;
                if (modelId) {
                    this.$store.dispatch('MODELS/GetModelData', modelId);
                }
            },
            setDefaultColor(isDefault) {
                this.$store.dispatch('COLORINGSYSTEMS/ClearTemplateColors');
                if (isDefault) {
                    this.selectedItem = 'Default Colors';
                } else {
                    this.selectedItem = 'Show Changes';
                }
                this.$store.commit('COLORINGSYSTEMS/SET_SELECTED_TEMPLATE_ID', '');
                this.$store.commit('SET_SHOW_DEFAULT_COLORS', isDefault);
                UpdateForgeElementsColors();
            },
            applyColors(item) {
                this.$store.commit('COLORINGSYSTEMS/SET_SELECTED_TEMPLATE_ID', item.id);
                this.selectedItem = item.name;
                this.$store.dispatch('COLORINGSYSTEMS/ApplyTemplateColors', item);
            },
        },
        computed: {
            viewer: {
                get: function () {
                    return this.$store.getters.VIEWER;
                },
                set: function (viewer) {
                    this.$store.commit('SET_VIEWER', viewer);
                },
            },
            currentModel: {
                get: function () {
                    return this.$store.getters['MODELS/CURRENT_MODEL_DATA'];
                },
            },
            currentMarkup: {
                get: function () {
                    return this.$store.getters['MARKUPDATA/MARKUP_VIEW_DATA'];
                },
            },
            isTouchDevice() {
                return isTouchDevice();
            },
            openMarkupImg() {
                return this.$store.getters['MARKUPDATA/OPEN_MARKUP_IMG'];
            },
            markupAttachImg() {
                return this.$store.getters['MARKUPDATA/SELECTED_MARKUP_IMG'];
            },
            stateInput() {
                return this.$store.getters['MARKUPEDITOR/SHOW_GLOBAL_MARKUP'];
            },
            currentColorMode() {
                return this.$store.getters.SHOW_DEFAULT_COLORS ? 'Default Colors' : 'Show Changes';
            },
            manipulatedElements() {
                return this.$store.getters.MANIPULATED_ELEMENTS;
            },
            selectedElementName() {
                return this.$store.getters.SELECTED_ELEMENT_NAME;
            },
            templates() {
                return this.$store.getters['COLORINGSYSTEMS/TEMPLATES'];
            },
            isCompareMode: {
                get() {
                    return this.$store.getters['COMPARE_TOOL/IS_COMPARING_MODE'];
                },
            },
        },
        mounted() {
            const markupId = this.$route.query.markupId;
            // const modelId = this.$route.params.id;

            if (markupId) {
                this.$store.dispatch('MARKUPEDITOR/setShowMarkupGlobal', true);
                this.$store.dispatch('MARKUPDATA/getMarkupById', { id: markupId }).then(() => {
                    this.initializeViewer();
                });
            } else {
                this.initializeViewer();
            }
        },
        watch: {
            $route: {
                handler(value, old) {
                    if (this.$route?.query?.view && this.$route?.query?.view !== old?.query?.view) {
                        var modelId = window.NOP_VIEWER?.model?.getDocumentNode()?.data?.guid;
                        // the following check bec of camera extension
                        if (!modelId || modelId != this.$route?.query?.view) {
                            this.OpenViewWithId(this.$route.query.view);
                        }
                    }
                },
                immediate: true,
            },
            currentModel: {
                handler: function (model, oldVal) {
                    let hasmarkup,
                        markupData = null;
                    if (this.currentMarkup) {
                        (hasmarkup = true),
                            (markupData = {
                                data: {
                                    id: this.currentMarkup.id,
                                    markupsPersist: this.currentMarkup.markupsPersist,
                                    viewerStatePersist: this.currentMarkup.viewerStatePersist,
                                    modelId: this.currentMarkup.modelId,
                                    isAttach: this.currentMarkup.isAttach,
                                },
                            });
                    }
                    if (model?.id) {
                        this.$store.dispatch('COLORINGSYSTEMS/GetTemplates', model.id);

                        this.$router.push({
                            name: 'ModelViewer',
                            params: { id: model.id },
                            query: { ...this.$route.query },
                        });
                    }

                    if (!model) {
                        this.$router.replace({
                            name: 'Home',
                        });
                    }

                    if (model?.urn && model?.urn !== oldVal?.urn) {
                        this.$store.dispatch('InitializeViewer', {
                            id: $base64ToUTF8(model.urn),
                            urn: model.urn,
                            ref: model.ref,
                            viewId: this.$route.query.view,
                            hasMarkup: hasmarkup,
                            markupData: markupData,
                        });
                    }
                },
                deep: true,
            },
            manipulatedElements: {
                handler() {
                    UpdateForgeElementsColors();
                },
                immediate: true,
                deep: true,
            },
            // currentMarkup(value) {
            //     this.$store.dispatch('MARKUPEDITOR/initializeMarkup', {
            //         data: {
            //             containerId: value.containerId,
            //             imgPath: value.imgPath,
            //             id: value.id,
            //             markupsPersist: value.markupsPersist,
            //             modelId: value.modelId,
            //             name: value.name,
            //             refId: value.refId,
            //             viewerStatePersist: value.viewerStatePersist,
            //             urn: value.urn,
            //             loadMarkup: true,
            //         },
            //     });
            // },
            currentColorMode(value) {
                this.selectedItem = value;
            },
        },
    };
</script>
<style lang="css">
    .viewer-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: relative;
    }

    .compare-mode .adsk-control.adsk-control-group:not(#diff-tool-group-show-diff):not(#diff-tool-button-comboSubMenu),
    .compare-mode .mode-button {
        display: none;
    }

    #forgeViewer {
        width: 100%;
        height: 100%;
        /* flex-grow: 1; */
        position: relative;
    }

    #parallelForgeViewer,
    #parallel3dViewer {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
    }

    .context-button {
        display: flex;
        position: absolute;
        z-index: 4;
        top: 15px;
        left: 40px;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    .recent-custom{
        position: absolute;
        z-index: 5;
        bottom: 0px;
        width: 100%;
        height: auto;
        cursor: pointer;
    }
    .mode-button {
        display: flex;
        position: absolute;
        z-index: 4;
        top: 10px;
        left: 40px;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .context-button:hover i {
        -webkit-animation: spin 5s linear infinite;
    }

    .context-button i {
        font-size: 20px;
        color: #000;
    }

    .context-button:hover i {
        color: #414141;
    }

    .topbar {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 7%;
        padding-bottom: 7%;
    }

    .status-bar {
        position: absolute;
        z-index: 4;
        top: 0;
        right: 0;
        padding: 5px;
        color: white;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-top: 16px;
        max-width: 400px;
        width: fit-content;
        padding: 8px 12px;
        left: 50%;
        transform: translate(-50%, 0);        
    } 
</style>
