/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-labels */

const Autodesk = window.Autodesk;

// import Block2dLibraryExtension from '@/extensions/Block2dLibraryExtension/Block2dLibraryExtension';
import { toast } from 'vue3-toastify';
import HttpService from '@Utils/httpService';
import { $base64ToUTF8 } from '@/utils/index.js';
import hubService from '@/hubService.js';
export const VersionsStore = {
    state: {
        syncVersion: null,
        latestVersion: null,
        allVersions: null,
        latestVersionLoading: false,
    },
    getters: {
        SYNC_VERSION(state) {
            return state.syncVersion;
        },
        LATEST_VERSION(state) {
            return state.latestVersion;
        },
        ALL_VERSIONS(state) {
            return state.allVersions;
        },
        LATEST_VERSION_LOADING(state) {
            return state.latestVersionLoading;
        },
    },
    mutations: {
        SET_SYNC_VERSION(state, syncVersion) {
            state.syncVersion = syncVersion;
        },
        SET_LATEST_VERSION(state, latestVersion) {
            state.latestVersion = latestVersion;
        },
        SET_ALL_VERSION(state, allVersions) {
            state.allVersions = allVersions;
        },
        SET_LATEST_VERSION_LOADING(state, value) {
            state.latestVersionLoading = value;
        },
    },
    actions: {
        GetModelVersions({ dispatch, getters }) {
            return new Promise((resolve, reject) => {
                var currentModel = getters['MODELS/CURRENT_MODEL_DATA'];

                HttpService.post({
                    url: `api/Models/getAllModelVersions`,
                    data: {
                        modelItemId: $base64ToUTF8(currentModel.urn),
                        itemId: currentModel.itemId,
                        projectId: currentModel.projectId,
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        GetLatestVersion({ dispatch, getters }) {
            return new Promise((resolve, reject) => {
                var currentModel = getters['MODELS/CURRENT_MODEL_DATA'];

                HttpService.post({
                    url: `api/Models/getLatestVersion`,
                    data: {
                        modelItemId: $base64ToUTF8(currentModel.urn),
                        itemId: currentModel.itemId,
                        projectId: currentModel.projectId,
                    },
                })
                    .then((response) => {
                        //   console.log(response);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        GetLatestVersion2({ dispatch, getters }, { urn, itemId, projectId }) {
            return new Promise((resolve, reject) => {
                HttpService.post({
                    url: `api/Models/getLatestVersion`,
                    data: {
                        modelItemId: $base64ToUTF8(urn),
                        itemId: itemId,
                        projectId: projectId,
                    },
                })
                    .then((response) => {
                        //   console.log(response);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        SaveNewModelVersion({ dispatch, getters }, saveNewModelVersion) {
            return new Promise((resolve, reject) => {
                var user = getters['ACCOUNT/USER'];
                var currentModel = getters['MODELS/CURRENT_MODEL_DATA'];
                var modelId = getters.MODEL_ID;

                HttpService.post({
                    url: `api/Models/saveNewModelVersion`,
                    data: {
                        modelItemId: $base64ToUTF8(currentModel.urn),
                        modelId,
                        modelOffset: getters['VIEWER/GLOBAL_OFFSET'],
                        modelStorageUrl: currentModel.storageUrl,
                        oldVersion: currentModel.version,
                        hubId: getters.HUB_ID,
                        userName: user.userName,
                        userId: user.userId,
                        connectionId: hubService.connection.connectionId,
                        saveNewVersion: saveNewModelVersion,
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        async CheckSyncVersion({ commit, dispatch, getters }) {
            var currentModel = getters['MODELS/CURRENT_MODEL_DATA'];
            var manifest = await dispatch('GetManifest', currentModel.urn);
            var derivative = manifest?.derivatives?.find((d) => d.outputType === 'svf');
            var syncVersion = derivative?.properties?.selfDocumentIdentity?.revitNumberOfSaves;
            commit('SET_SYNC_VERSION', syncVersion);
        },
        GetAllModelVersions({ commit, dispatch }) {
            commit('SET_ALL_VERSION', null);
            commit('SET_LATEST_VERSION', null);
            commit('SET_LATEST_VERSION_LOADING', true);
            dispatch('GetModelVersions')
                .then((versions) => {
                    commit('SET_ALL_VERSION', versions);
                    commit('SET_LATEST_VERSION', versions[0]);
                })
                .finally(() => {
                    commit('SET_LATEST_VERSION_LOADING', false);
                });
            // dispatch('GetLatestVersion')
            //     .then((latestVersion) => {
            //         commit('SET_LATEST_VERSION', latestVersion);
            //     })
            //     .finally(() => {
            //         commit('SET_LATEST_VERSION_LOADING', false);
            //     });
        },
    },
};

export default VersionsStore;
