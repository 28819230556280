<template>
  <v-dialog v-model="showPop" width="auto">
    <v-card :title="!IsStamp ?'Add Markup' : 'Add Stamp'" max-width="400" width="400" style="padding: 10px;" theme="dark">
      <v-text-field v-model="title" label="Title"></v-text-field>
      <img v-if="!IsStamp" :src="Href" id="popImage" name="popImage">
      <template v-slot:actions>
        <v-btn color="success"  text="Save" @click="Save()"></v-btn>
        <v-btn color="error"  text="Cancel" @click="SetShowPop()"></v-btn>

      </template>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      title:""
    };
  },
  
  computed: {
    showPop: {
      get() {
        return this.$store.getters['MARKUPEDITOR/MARKUP_POPUP'];
        
      }
    },
    Href(){
        return this.$store.getters['MARKUPEDITOR/MARKUP_HREF']
    },
    AttachId(){
      return this.$store.getters['MARKUPEDITOR/ATTACH_ID'];
    },
    IsAttach(){
     return this.$store.getters['MARKUPEDITOR/IS_ATTACH'];
    },
    IsStamp(){
     return this.$store.getters['MARKUPEDITOR/IS_STAMP'];
    }
  },
    watch: {
    showPop(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up
        console.log("Dialog was opened!")
        setTimeout(()=>{
        document.getElementById("popImage").src=window.hrefTemp

        },500)
      } else {
        console.log("Dialog was closed!")
      }
    },
    Href(value){
      console.log("href",value)
    }
  },
  methods:{
   SetShowPop(){
     this.$store.commit("MARKUPEDITOR/SET_MARKUP_POPUP",false)
     this.$store.dispatch("MARKUPEDITOR/ExitMarkup")

   },
    Save(){
     this.$store.commit("MARKUPEDITOR/SET_MARKUP_POPUP",false)
     
     if (window.layerName!=null && window.layerName!="") {
      this.$store.dispatch("MARKUPEDITOR/updateMarkup",{title:this.title}).then(()=>{
        this.$store.dispatch("MARKUPEDITOR/ExitMarkup")
      })
     }
     else{
      this.$store.dispatch("MARKUPEDITOR/saveMarkup",{isAttach:this.IsAttach,attachId:this.AttachId,title:this.title,ProjectName: this.$store.getters['MARKUPDATA/LOADED_MODEL_NAME'],IsStamp:this.IsStamp}).then(()=>{
        this.$store.dispatch("MARKUPEDITOR/ExitMarkup")
      })
     }


   }
  },
  props: ["isActive"],
  mounted() {
    
  }
};
</script>
<style>
.v-card-item {
    
    padding: 0.6rem 0.3rem !important;
}
</style>