
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
const Autodesk = window.Autodesk;
const avemc = Autodesk.Viewing.Extensions.Markups.Core;
const avemcu = Autodesk.Viewing.Extensions.Markups.Core.Utils;

import store from "@/store";
// import { onNewTestBlindMarkupCreated } from '../utils/testBlindUtils'
import $ from 'jquery';

class StampExtension extends Autodesk.Viewing.Extension {
    constructor(loadedViewer, options) {
        super(loadedViewer, options);
        this.viewer = loadedViewer;
        this.viewer.loadExtension('Autodesk.Viewing.MarkupsCore');
        this.markupCoreExt = this.viewer.getExtension('Autodesk.Viewing.MarkupsCore');
    }
    static get ExtensionId() {
        return 'Viewing.Extension.Stamp';
    }

    async load() {
        return true;
    }

    unload() {
        return true;
    }

    startDrawing(customSVG, markupLayer,size) {
        var _this = this;
        if (!customSVG) {
            return;
        }
        _this.markupCoreExt.show();
        _this.markupCoreExt.enterEditMode(markupLayer);
        _this.markupCoreExt.changeEditMode(new EditModeStamp(_this.markupCoreExt, customSVG,size));
    }

    startDrawingFromSvgText(svgText, markupLayer,size) {
        if (!svgText) {
            return;
        }
        var _this = this;
        let temp = document.createElement('div');
        temp.innerHTML = svgText;
        _this.startDrawing(temp.children[0], markupLayer,size)
    }

    startDrawingTestBlind(upperText = "XX", lowerText = "XXX", markupLayer, isSequential, isCombo) {
        var _this = this;

        //update sequence in the lower tag text
        // var testBlindOptions = store.getters['getTestBlindData'];
        //var sequences = store.state.MARKUPS.nextTestBlindSequence
        var sequence = 1// sequences[testBlindOptions.name]?.sequence ??;
        lowerText = sequence?.toString()?.padStart(3, '0') ?? 'XXX';

        var groupNumber = 1;
        const BUBBLE_SIZE = 50;
        const BUBBLE_OFFSET = 50;
        //const LEADER_LINE_TICK_LENGTH = 50;
        var groups = '';
        let groupElement = `
            <g class="stamp-circle-group" data-group="${groupNumber}" transform="scale(1) translate(${BUBBLE_OFFSET}, 500)" preserveAspectRatio="xMaxYMid meet"
                width="200px" height="200px" stroke-width="1" stroke="red" fill="white">
                <line id="leader-line-tick" vector-effect="non-scaling-stroke" x1="${BUBBLE_SIZE / 2}px" y1="0px" x2="${BUBBLE_OFFSET}px" y2="0px"/>
                <circle R="${BUBBLE_SIZE / 2}px" vector-effect="non-scaling-stroke" />
                <line id="circle-seperator-line" vector-effect="non-scaling-stroke" 
                    x1="${BUBBLE_SIZE / 2}px" y1="0px" x2="-${BUBBLE_SIZE / 2}px" y2="0px" ${isSequential ? '' : 'display= "none"'}/>
                <g id="stamp-text-group" fill="red" font-size="18px" storke-width="0" text-anchor="middle">
                    <text id="stamp-text-upper" x="0" y="${isSequential ? -7 : 8}">${upperText}</text>
                    <text id="stamp-text-lower" x="0" y="18px" ${isSequential ? '' : 'display= "none"'}>${lowerText}</text>
                </g>
            </g>
        `

        if (isCombo && Array.isArray(upperText)) {
            for (let i = 0; i < upperText.length; i++) {
                var text = upperText[i];
                groupNumber = i + 1;
                let groupElement = `
                <g class="stamp-circle-group" data-group="${groupNumber}" transform="scale(1) translate(${BUBBLE_OFFSET}, 500)" preserveAspectRatio="xMaxYMid meet"
                    width="200px" height="200px" stroke-width="1" stroke="red" fill="white">
                    <line id="leader-line-tick" ${groupNumber > 1 ? 'display="none"' : ''} vector-effect="non-scaling-stroke" x1="${BUBBLE_SIZE / 2}px" y1="0px" x2="${BUBBLE_OFFSET}px" y2="0px"/>
                    <circle R="${BUBBLE_SIZE / 2}px" vector-effect="non-scaling-stroke" />
                    <line id="circle-seperator-line" vector-effect="non-scaling-stroke"
                        x1="${BUBBLE_SIZE / 2}px" y1="0px" x2="-${BUBBLE_SIZE / 2}px" y2="0px" ${isSequential ? '' : 'display= "none"'}/>
                    <g id="stamp-text-group" fill="red" font-size="18px" storke-width="0" text-anchor="middle">
                        <text id="stamp-text-upper" x="0" y="${isSequential ? -7 : 8}">${text}</text>
                        <text id="stamp-text-lower" x="0" y="18px" ${isSequential ? '' : 'display= "none"'}>${lowerText}</text>
                    </g>
                </g>
            `
                groups += groupElement;

            }
        } else {
            groups = groupElement;
        }

        const SVG_TEMPLATE = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500" xml:space="preserve">
  <g cursor="default">
    <defs>
      <marker id="arrow-head" vector-effect="non-scaling-stroke" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto" markerUnits="strokeWidth">
        <polygon points="10 0, 10 7, 0 3.5" fill="red"/>
      </marker>
    </defs>
    <g id="stamp-marker-group" preserveAspectRatio="xMaxYMid meet" width="200px" height="200px" stroke-width="1">
      <line id="marker-line" vector-effect="non-scaling-stroke" x1="0" y1="0" x2="-10" y2="0" marker-start="url(#arrow-head)"/>
    </g>
    <line id="stamp-leader-line" vector-effect="non-scaling-stroke" x1="500" y1="0px" x2="0" y2="500" stroke="red" stroke-width="1"/>
    ${groups}
  </g>
</svg>
`;

        _this.startDrawingFromSvgText(SVG_TEMPLATE, markupLayer)

        //subscribe to EVENT_EDITMODE_CREATION_END
        _this.markupCoreExt.editMode.addEventListener(Autodesk.Viewing.Extensions.Markups.Core.EVENT_EDITMODE_CREATION_END, _this.onCreationEnded)
    }

    startDrawingFlagSymbol(markupLayer, isLarge) {
        var _this = this;
        const SMALL_FLAG_SIZE = 25;
        const LARGE_FLAG_SIZE = 50;
        var size = isLarge ? LARGE_FLAG_SIZE : SMALL_FLAG_SIZE;

        const FLAG_SVG_TEMPLATE = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500" xml:space="preserve">
  <g cursor="default">
    <line id="stamp-leader-line" vector-effect="non-scaling-stroke" x1="460" y1="40px" x2="0" y2="500" stroke="red" stroke-width="1" />
    <g id="flag-head-group" transform="scale(1) translate(150, 500) rotate(135)" preserveAspectRatio="xMaxYMid meet" stroke-width="1" width="200px" height="200px"  fill="red">
      <polygon vector-effect="non-scaling-stroke" points="${size} 0, ${0.5 * size} ${0.86 * size} , 0 0"/>
    </g>
  </g>
</svg>
`;

        _this.startDrawingFromSvgText(FLAG_SVG_TEMPLATE, markupLayer)

        //subscribe to EVENT_EDITMODE_CREATION_END
        _this.markupCoreExt.editMode.addEventListener(Autodesk.Viewing.Extensions.Markups.Core.EVENT_EDITMODE_CREATION_END, _this.onCreationEnded)
    }
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    }
    onCreationEnded(event) {
        var markupCoreExt = event.target.viewer.getExtension('Autodesk.Viewing.MarkupsCore');
        markupCoreExt.leaveEditMode();
        //store.commit('setMarkupActiveTool', '');
        if (!event.type == 'EVENT_EDITMODE_CREATION_END' || event.creationCancelled) {
            return;
        }

        //Set ID for the latest Markup
        var markupId = this.uuidv4();
        var selectedMarkup = markupCoreExt.markups.lastItem;
        if (!selectedMarkup) {
            return;
        }
        selectedMarkup.id = markupId;

        //update sequence in the lower tag text
        // var testBlindOptions = store.getters['getTestBlindData'];
        // var sequences = store.state.MARKUPS.nextTestBlindSequence;
        var sequence = 1//sequences[testBlindOptions.name]?.sequence ??;
        if (sequence) {
            var lowerText = sequence?.toString()?.padStart(3, '0');
            if (selectedMarkup?.group && selectedMarkup?.group?.querySelector('#stamp-text-lower')) {
                selectedMarkup.group.querySelector('#stamp-text-lower').innerHTML = lowerText;
            }
            selectedMarkup.group.setAttribute('markup-id', markupId);
            selectedMarkup.customSVG.innerHTML = selectedMarkup.group.innerHTML;
            selectedMarkup.customSVG.setAttribute('markup-id', markupId);
        }

        // if(testBlindOptions.isTestBlind) {
        //     onNewTestBlindMarkupCreated(markupId);
        // }

        //Increment Existing sequence
        var markupName = "testBlindOptions.name";

        // store.state.MARKUPS.nextTestBlindSequence[markupName] = store.state.MARKUPS.nextTestBlindSequence[markupName] ?? { 'abbreviation': testBlindOptions.abbreviation, sequence: 1 };
        // store.state.MARKUPS.nextTestBlindSequence[markupName].sequence = ++sequence;
    }

    startDrawingFromImage(image, markupLayer) {
        var _this = this;

        if (!image) {
            return;
        }

        fetch(image.src)
            .then(response => {
                return response.text();
            })
            .then(data => {
                let temp = document.createElement('div');
                temp.innerHTML = data;
                _this.startDrawing(temp.children[0], markupLayer)
            })
    }

    stopDrawing() {
        var _this = this;
        _this.markupCoreExt.leaveEditMode();
    }
}

class MarkupStamp extends avemc.Markup {
    constructor(id, editor, customSVG,size) {
        super(id, editor, ['stroke-width', 'stroke-color', 'stroke-opacity', 'fill-color', 'fill-opacity']);
        this.customSVG = customSVG;
        this.type = 'stamp';
        this.addMarkupMetadata = avemcu.addMarkupMetadata.bind(this);
        this.shape = avemcu.createSvgElement('g');
        this.size=size;

        this.group = avemcu.createSvgElement('g');

        let [width, height] = this.getDimensions(customSVG);
        this.group.innerHTML = customSVG?.innerHTML;

        // Something like: <path id="hitarea" fill="none" d="M 0 0 l 16 0 l 0 16 l -16 0 z" />
        let path = `M ${0} ${0} l ${width} 0 l 0 ${height} l ${-width} 0 z`;
        let hitarea = avemcu.createSvgElement('path');
        hitarea.setAttribute('id', "hitarea");
        hitarea.setAttribute('fill', "none");
        hitarea.setAttribute('d', path);
        this.group.appendChild(hitarea);
        // This is to standardize things:
        // width and height are 1 unit
        // position is in the centre
        // have to flip things because of y axis going upwards
        this.group.setAttribute('transform', `translate( -0.5 , 0.5 ) scale( ${1 / width} , ${-1 / height} )`)

        this.shape.appendChild(this.group);

        this.shape.hitarea = hitarea;
        this.shape.markup = hitarea;

        this.bindDomEvents();
    }
    getDimensions(customSVG) {
        return [this.size.x,this.size.y];

        // if (!customSVG) {
        //     return [93.09685459135015,75.70997967915983];
        // }
        // let vb = customSVG.getAttribute('viewBox');
        // if (!vb) return [80, 80];

        // let strings = vb.split(' ')
        // let width = parseInt(strings[2]);
        // let height = parseInt(strings[3]);

        // return [80, 80];
    }

    // Get a new edit mode object for this markup type.
    getEditMode() {
        return new EditModeStamp(this.editor);
    }

    // Update the markup's transform properties.
    set(position, size) {
        this.setSize(position, size.x, size.y);
    }

    // Update the markup's SVG shape based on its style and transform properties.
    updateStyle() {
        const { style, shape, size } = this;

        const strokeWidth = style['stroke-width'];
        const strokeColor = this.highlighted ? this.highlightColor : avemcu.composeRGBAString(style['stroke-color'], style['stroke-opacity']);
        const fillColor = strokeColor;

        // This only provides translation and rotation, not scale
        const transform = this.getTransform() + ` scale( ${size.x} , ${size.y} )`;

        let [width, height] = this.getDimensions(this.customSVG);
        //this.group.innerHTML = this.customSVG?.innerHTML;

        // Something like: <path id="hitarea" fill="none" d="M 0 0 l 16 0 l 0 16 l -16 0 z" />
        // let path = `M 0 0 l ${width} 0 l 0 ${height} l ${-width} 0 z`;
        // let hitarea = avemcu.createSvgElement('path');
        // hitarea.setAttribute('id', "hitarea");
        // hitarea.setAttribute('fill', "none");
        // hitarea.setAttribute('d', path);
        // this.group.removeChild(this.group.lastChild)
        // this.group.appendChild(hitarea)

        //this.group.appendChild(hitarea);

        shape.setAttribute('stroke-width', strokeWidth);
        //shape.setAttribute('stroke', strokeColor);
        //shape.setAttribute('fill', fillColor);
        shape.setAttribute('transform', transform);
    }

    // Store the markup's type, transforms, and styles in its SVG shape.
    setMetadata() {
        const metadata = avemcu.cloneStyle(this.style);
        metadata.type = this.type;
        metadata.position = [this.position.x, this.position.y].join(' ');
        metadata.size = [this.size.x, this.size.y].join(' ');
        metadata.rotation = String(this.rotation);
        return this.addMarkupMetadata(this.shape, metadata);
    }
}

class EditModeStamp extends avemc.EditMode {
    constructor(editor, customSVG,size) {

        super(editor, 'stamp', ['stroke-width', 'stroke-color', 'stroke-opacity', 'fill-color', 'fill-opacity']);
        this.customSVG = customSVG;
        this.size=size;
    }

    deleteMarkup(markup, cantUndo) {
        markup = markup || this.selectedMarkup;
        if (markup && markup.type == this.type) {
            const action = new StampDeleteAction(this.editor, markup);
            action.addToHistory = !cantUndo;
            action.execute();
            return true;
        }
        return false;
    }

    onMouseMove(event) {
        super.onMouseMove(event);

        var isCtrlPressed = event.ctrlKey;
        var { selectedMarkup, editor } = this;
        if (!selectedMarkup || !this.creating) {
            return;
        }

        let final = this.getFinalMouseDraggingPosition();
        final = editor.clientToMarkups(final.x, final.y);
        let position = {
            x: (this.firstPosition.x + final.x) * 0.5,
            y: (this.firstPosition.y + final.y) * 0.5
        };
        let size  ={
            x: Math.abs(this.firstPosition.x - final.x),
            y: Math.abs(this.firstPosition.y - final.y)
        };

        if (size.x == 0 || size.y == 0) {
            return;
        }

        var signX = Math.sign(final.x - this.firstPosition.x)
        var signY = Math.sign(final.y - this.firstPosition.y)

        //Check in which quadrant we are drawing
        //https://en.wikipedia.org/wiki/Quadrant_%28plane_geometry%29#/media/File:Cartesian_coordinates_2D.svg
        var quadrant = 0;

        if (signX >= 0 && signY >= 0) {
            quadrant = 1;

        } else if (signX < 0 && signY >= 0) {
            quadrant = 2;

        } else if (signX < 0 && signY < 0) {
            quadrant = 3;

        } else if (signX >= 0 && signY < 0) {
            quadrant = 4;

        }

        //Customize Leader of stamp
        this.markupCoreExt = this.viewer.getExtension('Autodesk.Viewing.MarkupsCore');


        //Assume ideal size for markup = 4, So other cases is factorized from this standard
        const STANDARD_BUBBLE_SIZE = 4;
        var scaleFactorX = STANDARD_BUBBLE_SIZE / size.x;
        var scaleFactorY = STANDARD_BUBBLE_SIZE / size.y;
        var rotationalAngle = Math.atan(size.y / size.x) * Math.RAD_PER_DEG

        const action = new StampUpdateAction(editor, selectedMarkup, position, size);
        action.execute();

        selectedMarkup = this.selectedMarkup;

        var tagCircleGroupElements = $(selectedMarkup?.group).find('.stamp-circle-group');
        var groups = [];
        for (let i = 0; i < tagCircleGroupElements.length; i++) {
            const element = tagCircleGroupElements[i];
            var group = {
                "element": element,
                "number": element.getAttribute('data-group')
            }
            groups.push(group);
        }

        var tagMarkerGroup = selectedMarkup?.group?.querySelector('#stamp-marker-group');
        var leaderLineElement = selectedMarkup?.group?.querySelector('#stamp-leader-line');
        var flagHeadElement = selectedMarkup?.group?.querySelector('#flag-head-group');

        //check if we are in dynamic SVG
        if (!leaderLineElement) {
            return;
        }

        if (isCtrlPressed) {
            scaleFactorY *= -1;
            rotationalAngle *= -1;
        }

        const BUBBLE_SIZE = 50;
        const BUBBLE_OFFSET = 50;
        const LEADER_LINE_TICK_LENGTH = 25;

        switch (quadrant) {
            case 1:
                groups.forEach((group) => {
                    var number = group.number;
                    var element = group.element;
                    element?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(${(500 / scaleFactorX) + (number - 1) * BUBBLE_SIZE + BUBBLE_OFFSET}, 0)`);
                    element?.querySelector('#leader-line-tick')?.setAttribute('x1', -1 * BUBBLE_OFFSET);
                    element?.querySelector('#leader-line-tick')?.setAttribute('x2', -1 * LEADER_LINE_TICK_LENGTH);
                })

                tagMarkerGroup?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(0, ${(500 / scaleFactorY)}) rotate(${180 - rotationalAngle})`)
                flagHeadElement?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(${(500 / scaleFactorX)}, 0) rotate(${-rotationalAngle})`);
                leaderLineElement?.setAttribute('x1', '0');
                leaderLineElement?.setAttribute('y1', '500');
                leaderLineElement?.setAttribute('x2', '500');
                leaderLineElement?.setAttribute('y2', '0');
                break;

            case 2:
                groups.forEach((group) => {
                    var number = group.number;
                    var element = group.element;
                    element?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(-${(number - 1) * BUBBLE_SIZE + BUBBLE_OFFSET}, 0)`);
                    element?.querySelector('#leader-line-tick')?.setAttribute('x1', LEADER_LINE_TICK_LENGTH);
                    element?.querySelector('#leader-line-tick')?.setAttribute('x2', BUBBLE_OFFSET);
                })

                tagMarkerGroup?.setAttribute('transform', `scale(${scaleFactorX / 4 + ',' + scaleFactorY / 4}) translate(${(500 * 4 / scaleFactorX)}, ${(500 * 4 / scaleFactorY)}) rotate(${rotationalAngle})`)
                flagHeadElement?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(0, 0) rotate(${rotationalAngle})`);
                leaderLineElement?.setAttribute('x1', '500');
                leaderLineElement?.setAttribute('y1', '500');
                leaderLineElement?.setAttribute('x2', '0');
                leaderLineElement?.setAttribute('y2', '0');
                break;

            case 3:
                groups.forEach((group) => {
                    var number = group.number;
                    var element = group.element;
                    element?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(-${(number - 1) * BUBBLE_SIZE + BUBBLE_OFFSET}, ${500 / scaleFactorY})`);
                    element?.querySelector('#leader-line-tick')?.setAttribute('x1', LEADER_LINE_TICK_LENGTH);
                    element?.querySelector('#leader-line-tick')?.setAttribute('x2', BUBBLE_OFFSET);
                })

                tagMarkerGroup?.setAttribute('transform', `scale(${scaleFactorX / 4 + ',' + scaleFactorY / 4}) translate(${(500 * 4 / scaleFactorX)}, 0) rotate(${-rotationalAngle})`)
                flagHeadElement?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(0, ${500 / scaleFactorY}) rotate(${360 - rotationalAngle})`);
                leaderLineElement?.setAttribute('x1', '500');
                leaderLineElement?.setAttribute('y1', '0');
                leaderLineElement?.setAttribute('x2', '0');
                leaderLineElement?.setAttribute('y2', '500');
                break;

            case 4:
                groups.forEach((group) => {
                    var number = group.number;
                    var element = group.element;
                    element?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(${(500 / scaleFactorX) + (number - 1) * BUBBLE_SIZE + BUBBLE_OFFSET}, ${500 / scaleFactorY})`);
                    element?.querySelector('#leader-line-tick')?.setAttribute('x1', -1 * BUBBLE_OFFSET);
                    element?.querySelector('#leader-line-tick')?.setAttribute('x2', -1 * LEADER_LINE_TICK_LENGTH);
                })

                tagMarkerGroup?.setAttribute('transform', `scale(${scaleFactorX / 4 + ',' + scaleFactorY / 4}) translate(0, 0) rotate(${180 + rotationalAngle})`)
                flagHeadElement?.setAttribute('transform', `scale(${scaleFactorX + ',' + scaleFactorY}) translate(${(500 / scaleFactorX)}, ${500 / scaleFactorY}) rotate(${rotationalAngle})`);
                leaderLineElement?.setAttribute('x1', '0');
                leaderLineElement?.setAttribute('y1', '0');
                leaderLineElement?.setAttribute('x2', '500');
                leaderLineElement?.setAttribute('y2', '500');
                break;

            default:
                break;
        }


    }
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    }

    onMouseDown() {
        super.onMouseDown();
        const { selectedMarkup, editor } = this;
        if (selectedMarkup) {
            return;
        }

        // Calculate center and size.
        let mousePosition = editor.getMousePosition();
        this.initialX = mousePosition.x;
        this.initialY = mousePosition.y;
        let position = this.firstPosition = editor.clientToMarkups(this.initialX, this.initialY);
        //let size = editor.sizeFromClientToMarkups(1, 1);

        editor.beginActionGroup();
        const markupId = this.uuidv4();
        const action = new StampCreateAction(editor, markupId, position, this.size, 0, this.style, this.customSVG);
        action.execute();

        this.selectedMarkup = editor.getMarkup(markupId);
        this.creationBegin();
    }
}

export class StampCreateAction extends avemc.EditAction {
    constructor(editor, id, position, size, rotation, style, customSVG) {
        super(editor, 'CREATE-STAMP', id);
        this.customSVG = customSVG;
        this.selectOnExecution = false;
        this.position = { x: position.x, y: position.y };
        this.size = { x: size.x, y: size.y };
        this.rotation = rotation;
        this.style = avemcu.cloneStyle(style);
        this.style['stroke-width'] = 0;
    }

    redo() {
        const editor = this.editor;
        const stamp = new MarkupStamp(this.targetId, editor, this.customSVG,this.size);
        editor.addMarkup(stamp);
        stamp.setSize(this.position, this.size.x, this.size.y);
        stamp.setRotation(this.rotation);
        stamp.setStyle(this.style);
    }

    undo() {
        const markup = this.editor.getMarkup(this.targetId);
        markup && this.editor.removeMarkup(markup);
    }
}

class StampUpdateAction extends avemc.EditAction {
    constructor(editor, stamp, position, size) {
        super(editor, 'UPDATE-STAMP', stamp.id);
        this.newPosition = { x: position.x, y: position.y };
        this.newSize = { x: size.x, y: size.y };
        this.oldPosition = { x: stamp.position.x, y: stamp.position.y };
        this.oldSize = { x: stamp.size.x, y: stamp.size.y };
    }

    redo() {
        this.applyState(this.targetId, this.newPosition, this.newSize);
    }

    undo() {
        this.applyState(this.targetId, this.oldPosition, this.oldSize);
    }

    merge(action) {
        if (this.targetId === action.targetId && this.type === action.type) {
            this.newPosition = action.newPosition;
            this.newSize = action.newSize;
            return true;
        }
        return false;
    }

    applyState(targetId, position, size) {
        const stamp = this.editor.getMarkup(targetId);
        if (!stamp) {
            return;
        }

        // Different stroke widths make positions differ at sub-pixel level.
        const epsilon = 0.0001;
        if (Math.abs(stamp.position.x - position.x) > epsilon || Math.abs(stamp.size.y - size.y) > epsilon ||
            Math.abs(stamp.position.y - position.y) > epsilon || Math.abs(stamp.size.y - size.y) > epsilon) {
            stamp.set(position, size);
        }
    }

    isIdentity() {
        return (
            this.newPosition.x === this.oldPosition.x &&
            this.newPosition.y === this.oldPosition.y &&
            this.newSize.x === this.oldSize.x &&
            this.newSize.y === this.oldSize.y
        );
    }
}

class StampDeleteAction extends avemc.EditAction {
    constructor(editor, stamp) {
        super(editor, 'DELETE-STAMP', stamp.id);
        this.createStamp = new StampCreateAction(
            editor,
            stamp.id,
            stamp.position,
            stamp.size,
            stamp.rotation,
            stamp.getStyle()
        );
    }

    redo() {
        this.createStamp.undo();
    }

    undo() {
        this.createStamp.redo();
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(StampExtension.ExtensionId, StampExtension);
export default StampExtension;