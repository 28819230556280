/* eslint-disable */
import CompareToolExtension from "@/extensions/CompareTool/CompareToolExtension";

const COMPARE_TOOL = {
	namespaced: true,
	state: {
		showComparePopup: false,
		isComparingMode: false
	},
	getters: {
		SHOW_COMPARE_POPUP(state){
			return state.showComparePopup;
		},
		IS_COMPARING_MODE(state){
			return state.isComparingMode;
		}
	},
	mutations: {
		SET_SHOW_COMPARE_POPUP(state, payload) {
			state.showComparePopup = payload;
		},
		SET_IS_COMPARING_MODE(state, payload) {
			state.isComparingMode = payload;
		}
	},
}

export default COMPARE_TOOL