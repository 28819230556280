/* eslint-disable no-unused-vars */
/* eslint-disable no-async-promise-executor */
const THREE = window.THREE
const Autodesk = window.Autodesk
import { getModelElementBoundingBox2D, getWorldBoundingBox } from '@/store/modules/ForgeService';
export default class ViewerToolkit {
    ///////////////////////////////////////////////////////////////////
    //
    //
    ///////////////////////////////////////////////////////////////////
    static guid(format = 'xxxxxxxxxxxx') {
        var d = new Date().getTime();

        var guid = format.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
        });

        return guid;
    }

    /////////////////////////////////////////////
    //mobile detection
    //
    /////////////////////////////////////////////
    static get mobile() {
        return {
            getUserAgent: function () {
                return navigator.userAgent;
            },
            isAndroid: function () {
                return this.getUserAgent().match(/Android/i);
            },
            isBlackBerry: function () {
                return this.getUserAgent().match(/BlackBerry/i);
            },
            isIOS: function () {
                return this.getUserAgent().match(/iPhone|iPad|iPod/i);
            },
            isOpera: function () {
                return this.getUserAgent().match(/Opera Mini/i);
            },
            isWindows: function () {
                return this.isWindowsDesktop() || this.isWindowsMobile();
            },
            isWindowsMobile: function () {
                return this.getUserAgent().match(/IEMobile/i);
            },
            isWindowsDesktop: function () {
                return this.getUserAgent().match(/WPDesktop/i);
            },
            isAny: function () {
                return (
                    this.isAndroid() ||
                    this.isBlackBerry() ||
                    this.isIOS() ||
                    this.isWindowsMobile()
                );
            },
        };
    }

    //////////////////////////////////////////////////////////////////////////
    // Return default viewable path: first 3d or 2d item
    //
    //////////////////////////////////////////////////////////////////////////
    static getDefaultViewablePath(doc, roles = ['3d', '2d']) {
        var rootItem = doc.getRootItem();

        let roleArray = [...roles];

        let items = [];

        roleArray.forEach((role) => {
            items = [
                ...items,
                ...Autodesk.Viewing.Document.getSubItemsWithProperties(
                    rootItem,
                    { type: 'geometry', role },
                    true,
                ),
            ];
        });

        return items.length ? doc.getViewablePath(items[0]) : null;
    }

    /////////////////////////////////////////////////////////////////
    // Toolbar button
    //
    /////////////////////////////////////////////////////////////////
    static createButton(id, className, tooltip, handler) {
        var button = new Autodesk.Viewing.UI.Button(id);

        button.icon.style.fontSize = '24px';

        button.icon.className = className;

        button.setToolTip(tooltip);

        button.onClick = handler;

        return button;
    }

    /////////////////////////////////////////////////////////////////
    // Control group
    //
    /////////////////////////////////////////////////////////////////
    static createControlGroup(viewer, ctrlGroupName) {
        var viewerToolbar = viewer.getToolbar(true);

        if (viewerToolbar) {
            var ctrlGroup = new Autodesk.Viewing.UI.ControlGroup(ctrlGroupName);

            viewerToolbar.addControl(ctrlGroup);

            return ctrlGroup;
        }
    }

    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
    static getLeafNodes(model, dbIds) {
        return new Promise((resolve, reject) => {
            try {
                const instanceTree = model.getData().instanceTree;

                dbIds = dbIds || instanceTree.getRootId();

                const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];

                let leafIds = [];

                const getLeafNodesRec = (id) => {
                    var childCount = 0;

                    instanceTree.enumNodeChildren(id, (childId) => {
                        getLeafNodesRec(childId);

                        ++childCount;
                    });

                    if (childCount == 0) {
                        leafIds.push(id);
                    }
                };

                for (var i = 0; i < dbIdArray.length; ++i) {
                    getLeafNodesRec(dbIdArray[i]);
                }

                return resolve(leafIds);
            } catch (ex) {
                return reject(ex);
            }
        });
    }

    /////////////////////////////////////////////////////////////////
    // get node fragIds
    //
    /////////////////////////////////////////////////////////////////
    static getFragIds(model, dbIds) {
        return new Promise(async (resolve, reject) => {
            try {
                const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];

                const instanceTree = model.getData().instanceTree;

                const leafIds = await ViewerToolkit.getLeafNodes(model, dbIdArray);

                let fragIds = [];

                for (var i = 0; i < leafIds.length; ++i) {
                    instanceTree.enumNodeFragments(leafIds[i], (fragId) => {
                        fragIds.push(fragId);
                    });
                }

                return resolve(fragIds);
            } catch (ex) {
                return reject(ex);
            }
        });
    }

    /////////////////////////////////////////////////////////////////
    // Node bounding box
    //
    /////////////////////////////////////////////////////////////////
    static getWorldBoundingBox(model, dbId) {
        return new Promise(async (resolve, reject) => {
            try {
                var fragIds = await ViewerToolkit.getFragIds(model, dbId);

                if (!fragIds.length) {
                    return reject('No geometry, invalid dbId?');
                }

                var fragList = model.getFragmentList();

                var fragbBox = new THREE.Box3();
                var nodebBox = new THREE.Box3();

                fragIds.forEach(function (fragId) {
                    fragList.getWorldBounds(fragId, fragbBox);
                    nodebBox.union(fragbBox);
                });

                return resolve(nodebBox);
            } catch (ex) {
                return reject(ex);
            }
        });
    }

    /////////////////////////////////////////////////////////////////
    // Gets properties from component
    //
    /////////////////////////////////////////////////////////////////
    static getProperties(model, dbId, requestedProps = null) {
        return new Promise((resolve, reject) => {
            try {
                if (requestedProps) {
                    const propTasks = requestedProps.map((displayName) => {
                        return ViewerToolkit.getProperty(model, dbId, displayName, 'Not Available');
                    });

                    Promise.all(propTasks).then((properties) => {
                        resolve(properties);
                    });
                } else {
                    model.getProperties(dbId, function (result) {
                        if (result.properties) {
                            return resolve(result.properties);
                        }

                        return reject('No Properties');
                    });
                }
            } catch (ex) {
                console.log(ex);
                return reject(ex);
            }
        });
    }

    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
    static getProperty(model, dbId, displayName, defaultValue) {
        return new Promise((resolve, reject) => {
            try {
                model.getProperties(dbId, function (result) {
                    if (result.properties) {
                        result.properties.forEach((prop) => {
                            if (typeof displayName === 'function') {
                                if (displayName(prop.displayName)) {
                                    resolve(prop);
                                }
                            } else if (displayName === prop.displayName) {
                                resolve(prop);
                            }
                        });

                        if (defaultValue) {
                            return resolve({
                                displayValue: defaultValue,
                                displayName,
                            });
                        }

                        reject(new Error('Not Found'));
                    } else {
                        reject(new Error('Error getting properties'));
                    }
                });
            } catch (ex) {
                return reject(ex);
            }
        });
    }

    /////////////////////////////////////////////////////////////////
    // Gets all existing properties from component  dbIds
    //
    /////////////////////////////////////////////////////////////////
    static getPropertyList(model, dbIds) {
        return new Promise(async (resolve, reject) => {
            try {
                var propertyTasks = dbIds.map((dbId) => {
                    return ViewerToolkit.getProperties(model, dbId);
                });

                var propertyResults = await Promise.all(propertyTasks);

                var properties = [];

                propertyResults.forEach((propertyResult) => {
                    propertyResult.forEach((prop) => {
                        if (properties.indexOf(prop.displayName) < 0) {
                            properties.push(prop.displayName);
                        }
                    });
                });

                return resolve(properties.sort());
            } catch (ex) {
                return reject(ex);
            }
        });
    }

    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
    static getBulkPropertiesAsync(model, dbIds, propFilter) {
        return new Promise((resolve, reject) => {
            model.getBulkProperties(
                dbIds,
                propFilter,
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    /////////////////////////////////////////////////////////////////
    // Maps components by property
    //
    /////////////////////////////////////////////////////////////////
    static mapComponentsByProp(model, propName, components, defaultProp) {
        return new Promise(async (resolve, reject) => {
            try {
                const results = await ViewerToolkit.getBulkPropertiesAsync(model, components, [
                    propName,
                ]);

                const propertyResults = results.map((result) => {
                    return Object.assign({}, result.properties[0], {
                        dbId: result.dbId,
                    });
                });

                var componentsMap = {};

                propertyResults.forEach((result) => {
                    var value = result.displayValue;

                    if (typeof value == 'string') {
                        value = value.split(':')[0];
                    }

                    if (!componentsMap[value]) {
                        componentsMap[value] = [];
                    }

                    componentsMap[value].push(result.dbId);
                });

                return resolve(componentsMap);
            } catch (ex) {
                return reject(ex);
            }
        });
    }

    /////////////////////////////////////////////////////////////
    // Runs recursively the argument task on each node
    // of the data tree
    //
    /////////////////////////////////////////////////////////////
    static runTaskOnDataTree(root, taskFunc) {
        var tasks = [];

        var runTaskOnDataTreeRec = (node, parent = null) => {
            if (node.children) {
                node.children.forEach((childNode) => {
                    runTaskOnDataTreeRec(childNode, node);
                });
            }

            var task = taskFunc(node, parent);

            tasks.push(task);
        };

        runTaskOnDataTreeRec(root);

        return Promise.all(tasks);
    }

    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
    static drawBox(viewer, min, max, material = null) {
        var _material = material;

        if (!_material) {
            _material = new THREE.LineBasicMaterial({
                color: 0xffff00,
                linewidth: 2,
            });

            viewer.impl.matman().addMaterial('ADN-Material-Line', _material, true);
        }

        function drawLines(coordsArray, mat) {
            var lines = [];

            for (var i = 0; i < coordsArray.length; i += 2) {
                var start = coordsArray[i];
                var end = coordsArray[i + 1];

                var geometry = new THREE.Geometry();

                geometry.vertices.push(new THREE.Vector3(start.x, start.y, start.z));

                geometry.vertices.push(new THREE.Vector3(end.x, end.y, end.z));

                geometry.computeLineDistances();

                var line = new THREE.Line(geometry, mat);

                viewer.impl.scene.add(line);

                lines.push(line);
            }

            return lines;
        }

        var lines = drawLines(
            [
                { x: min.x, y: min.y, z: min.z },
                { x: max.x, y: min.y, z: min.z },

                { x: max.x, y: min.y, z: min.z },
                { x: max.x, y: min.y, z: max.z },

                { x: max.x, y: min.y, z: max.z },
                { x: min.x, y: min.y, z: max.z },

                { x: min.x, y: min.y, z: max.z },
                { x: min.x, y: min.y, z: min.z },

                { x: min.x, y: max.y, z: max.z },
                { x: max.x, y: max.y, z: max.z },

                { x: max.x, y: max.y, z: max.z },
                { x: max.x, y: max.y, z: min.z },

                { x: max.x, y: max.y, z: min.z },
                { x: min.x, y: max.y, z: min.z },

                { x: min.x, y: max.y, z: min.z },
                { x: min.x, y: max.y, z: max.z },

                { x: min.x, y: min.y, z: min.z },
                { x: min.x, y: max.y, z: min.z },

                { x: max.x, y: min.y, z: min.z },
                { x: max.x, y: max.y, z: min.z },

                { x: max.x, y: min.y, z: max.z },
                { x: max.x, y: max.y, z: max.z },

                { x: min.x, y: min.y, z: max.z },
                { x: min.x, y: max.y, z: max.z },
            ],

            _material,
        );

        viewer.impl.sceneUpdated(true);

        return lines;
    }

    /////////////////////////////////////////////////////////////////
    // Set component material
    //
    /////////////////////////////////////////////////////////////////
    static async setMaterial(model, dbId, material) {
        const fragIds = await ViewerToolkit.getFragIds(model, dbId);

        const fragList = model.getFragmentList();

        fragIds.forEach((fragId) => {
            fragList.setMaterial(fragId, material);
        });
    }

    /////////////////////////////////////////////////////////////////
    // Recursively builds the model tree
    //
    /////////////////////////////////////////////////////////////////
    static buildModelTree(model, createNodeFunc = null) {
        //builds model tree recursively
        function _buildModelTreeRec(node) {
            instanceTree.enumNodeChildren(node.dbId, function (childId) {
                var childNode = null;

                if (createNodeFunc) {
                    childNode = createNodeFunc(childId);
                } else {
                    node.children = node.children || [];

                    childNode = {
                        dbId: childId,
                        name: instanceTree.getNodeName(childId),
                    };

                    node.children.push(childNode);
                }

                _buildModelTreeRec(childNode);
            });
        }

        //get model instance tree and root component
        var instanceTree = model.getData().instanceTree;

        var rootId = instanceTree.getRootId();

        var rootNode = {
            dbId: rootId,
            name: instanceTree.getNodeName(rootId),
        };

        _buildModelTreeRec(rootNode);

        return rootNode;
    }

    /////////////////////////////////////////////////////////////////
    // Recursively execute task on model tree
    //
    /////////////////////////////////////////////////////////////////
    static executeTaskOnModelTree(model, task) {
        var taskResults = [];

        function _executeTaskOnModelTreeRec(dbId) {
            instanceTree.enumNodeChildren(dbId, function (childId) {
                taskResults.push(task(model, childId));

                _executeTaskOnModelTreeRec(childId);
            });
        }

        //get model instance tree and root component
        var instanceTree = model.getData().instanceTree;

        var rootId = instanceTree.getRootId();

        _executeTaskOnModelTreeRec(rootId);

        return taskResults;
    }

    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
    static isolateFull(viewer, model = null, dbIds = []) {
        return new Promise(async (resolve, reject) => {
            try {
                model = model || viewer.model;

                viewer.isolate(dbIds);

                const targetIds = Array.isArray(dbIds) ? dbIds : [dbIds];

                const targetLeafIds = await ViewerToolkit.getLeafNodes(model, targetIds);

                const leafIds = await ViewerToolkit.getLeafNodes(model);

                const leafTasks = leafIds.map((dbId) => {
                    return new Promise((resolveLeaf) => {
                        const show = !targetLeafIds.length || targetLeafIds.indexOf(dbId) > -1;

                        viewer.impl.visibilityManager.setNodeOff(dbId, !show);

                        resolveLeaf();
                    });
                });

                return Promise.all(leafTasks);
            } catch (ex) {
                return reject(ex);
            }
        });
    }

    static pointerToRaycaster(viewer, pointer) {
        let domElement = viewer.canvas;
        let camera = viewer.impl.camera;
        const pointerVector = new THREE.Vector3();
        const pointerDir = new THREE.Vector3();
        const ray = new THREE.Raycaster();

        const rect = domElement.getBoundingClientRect();

        let clientX, clientY;

        if (pointer.clientX !== undefined && pointer.clientY !== undefined) {
            // Mouse event
            clientX = pointer.clientX;
            clientY = pointer.clientY;
        } else if (pointer.pointers && pointer.pointers.length > 0) {
            // Touch event, take the first pointer
            clientX = pointer.pointers[0].clientX;
            clientY = pointer.pointers[0].clientY;
        } else if (pointer.touches && pointer.touches.length > 0) {
            // Touch event, take the first touch
            clientX = pointer.touches[0].clientX;
            clientY = pointer.touches[0].clientY;
        } else {
            // Unknown event, return null ray
            return null;
        }

        const x = ((clientX - rect.left) / rect.width) * 2 - 1;
        const y = -((clientY - rect.top) / rect.height) * 2 + 1;

        if (camera.isPerspective) {
            pointerVector.set(x, y, 0.5);

            pointerVector.unproject(camera);

            ray.set(camera.position, pointerVector.sub(camera.position).normalize());
        } else {
            pointerVector.set(x, y, -1);

            pointerVector.unproject(camera);

            pointerDir.set(0, 0, -1);

            ray.set(pointerVector, pointerDir.transformDirection(camera.matrixWorld));
        }

        return ray;
    }

    static drawBoundingBox(viewer, bBox, color = 0xffff00) {
        // Calculate the size and center of the bounding box
        const size = new THREE.Vector3();
        const center = new THREE.Vector3();
        bBox.getSize(size);
        bBox.getCenter(center);

        // Create the box geometry based on the size of the bounding box
        const geometry = new THREE.BoxGeometry(size.x, size.y, size.z);

        // Create a mesh material using the provided color
        const material = new THREE.MeshBasicMaterial({
            color: color,
            transparent: true,
            opacity: 0.1,
        });

        // Create a mesh using the geometry and material
        const mesh = new THREE.Mesh(geometry, material);

        // Move the mesh to the center of the bounding box
        mesh.position.copy(center);

        // Add the mesh to the viewer's overlay scene (or main scene)
        viewer.impl.createOverlayScene('customBoundingBoxOverlay');
        viewer.impl.addOverlay('customBoundingBoxOverlay', mesh);
        viewer.impl.invalidate(true, true, true);
    }

    static sheetToWorld(viewer, sheetPos, model2d, globalOffset) {
        const viewportExt = viewer.getExtension('Autodesk.AEC.ViewportsExtension');

        sheetPos = new THREE.Vector3(sheetPos.x, sheetPos.y, 0);

        const viewport = viewportExt.findViewportAtPoint(
            model2d,
            new THREE.Vector2(sheetPos.x, sheetPos.y),
        );

        if (!viewport) return null;

        const sheetUnitScale = model2d.getUnitScale();

        const matrix = viewport.get2DTo3DMatrix(sheetUnitScale);

        const worldPos = sheetPos.clone().applyMatrix4(matrix).sub(globalOffset);

        return worldPos;
    }

    static worldToSheet(modelPos, model2d, transform3d) {
        const viewports = Autodesk.AEC.AecModelData.findViewportsOnSheet(model2d);

        if (!viewports || viewports.length <= 0) return null;

        const viewport = Array.isArray(viewports) ? viewports[0] : viewports;

        const sheetUnitScale = model2d.getUnitScale();

        const sheetMatrix = Autodesk.AEC.AecModelData.get3DTo2DMatrix(
            viewport,
            sheetUnitScale,
        ).clone();

        sheetMatrix.multiply(transform3d);

        const sheetPos = modelPos.clone().applyMatrix4(sheetMatrix);

        return sheetPos;
    }
    static map3DTo2D(point3D, known3DPoints, known2DPoints) {
        // known3DPoints: Array of 2 known points in 3D space (e.g., [{x: x1, y: y1, z: z1}, {x: x2, y: y2, z: z2}])
        // known2DPoints: Array of 2 known points in 2D sheet (e.g., [{x: x1, y: y1}, {x: x2, y: y2}])
        // point3D: the point in 3D space to map (e.g., {x: x, y: y, z: z})

        const [p1_3D, p2_3D] = known3DPoints;
        const [p1_2D, p2_2D] = known2DPoints;

        // Calculate distances in 3D for x-axis and y-axis separately
        const dist3D_X = Math.abs(p2_3D.x - p1_3D.x);
        const dist3D_Y = Math.abs(p2_3D.y - p1_3D.y);

        // Calculate distances in 2D for x-axis and y-axis separately
        const dist2D_X = Math.abs(p2_2D.x - p1_2D.x);
        const dist2D_Y = Math.abs(p2_2D.y - p1_2D.y);

        // Scale factors for each axis
        const scaleFactor_X = dist2D_X / dist3D_X;
        const scaleFactor_Y = dist2D_Y / dist3D_Y;

        // Calculate offsets of the input 3D point from the first known 3D point
        const offset3D_X = point3D.x - p1_3D.x;
        const offset3D_Y = point3D.y - p1_3D.y;

        // Map the 3D offsets to 2D
        const offset2D_X = offset3D_X * scaleFactor_X;
        const offset2D_Y = offset3D_Y * scaleFactor_Y;

        // Compute the final 2D point
        const point2D = {
            x: p1_2D.x + offset2D_X,
            y: p1_2D.y + offset2D_Y,
        };

        return new THREE.Vector3(point2D.x, point2D.y, 0);
    }
    static map2DTo3D(point2D, known2DPoints, known3DPoints) {
        // known2DPoints: Array of 2 known points in 2D sheet (e.g., [{x: x1, y: y1}, {x: x2, y: y2}])
        // known3DPoints: Array of 2 known points in 3D space (e.g., [{x: x1, y: y1, z: z1}, {x: x2, y: y2, z: z2}])
        // point2D: the point on the 2D sheet to map (e.g., {x: x, y: y})

        const [p1_2D, p2_2D] = known2DPoints;
        const [p1_3D, p2_3D] = known3DPoints;

        // Calculate distances in 2D for x-axis and y-axis separately
        const dist2D_X = Math.abs(p2_2D.x - p1_2D.x);
        const dist2D_Y = Math.abs(p2_2D.y - p1_2D.y);

        // Calculate distances in 3D for x-axis and y-axis separately
        const dist3D_X = Math.abs(p2_3D.x - p1_3D.x);
        const dist3D_Y = Math.abs(p2_3D.y - p1_3D.y);

        // Scale factors for each axis
        const scaleFactor_X = dist3D_X / dist2D_X;
        const scaleFactor_Y = dist3D_Y / dist2D_Y;

        // Calculate the offset of the input 2D point from the first known 2D point
        const offset2D_X = point2D.x - p1_2D.x;
        const offset2D_Y = point2D.y - p1_2D.y;

        // Map the 2D offsets to 3D
        const offset3D_X = offset2D_X * scaleFactor_X;
        const offset3D_Y = offset2D_Y * scaleFactor_Y;

        // Compute the corresponding 3D position (assuming no Z-axis movement for simplicity)
        const point3D = {
            x: p1_3D.x + offset3D_X,
            y: p1_3D.y + offset3D_Y,
            z: p1_3D.z, // Assuming that the Z position remains the same
        };

        return new THREE.Vector3(point3D.x, point3D.y, point3D.z);
    }

    static async getViewabelIdsOfElement(model, dbId, store) {
        return new Promise((resolve) => {
            model.getProperties(dbId, (props) => {
                const viwableIds = props.properties
                    .filter((x) => x.displayCategory === '__viewable_in__')
                    .map((x) => x.displayValue);

                let viewsGuids = [];
                const views = store.getters.D2ViewItems.concat(store.getters.D3ViewItems);
                for (let i = 0; i < viwableIds.length; i++) {
                    const v = views.find((x) => x.data.viewableID == viwableIds[i]);
                    if (!v) continue;
                    viewsGuids.push(v.data.guid);
                }
                resolve(viewsGuids.join(','));
            });
        });
    }

    static getRotatedMatrix(_matrix, angle) {
        let matrix = _matrix.clone();

        const rotatedmatrix = new THREE.Matrix3();

        rotatedmatrix.elements[0] = Math.cos(angle);
        rotatedmatrix.elements[1] = -Math.sin(angle);
        rotatedmatrix.elements[2] = 0;
        rotatedmatrix.elements[3] = Math.sin(angle);
        rotatedmatrix.elements[4] = Math.cos(angle);
        rotatedmatrix.elements[5] = 0;
        rotatedmatrix.elements[6] = 0;
        rotatedmatrix.elements[7] = 0;
        rotatedmatrix.elements[8] = 1;

        const originalmatrix = new THREE.Matrix3();

        originalmatrix.elements[0] = matrix.elements[0];
        originalmatrix.elements[1] = matrix.elements[1];
        originalmatrix.elements[2] = matrix.elements[2];
        originalmatrix.elements[3] = matrix.elements[4];
        originalmatrix.elements[4] = matrix.elements[5];
        originalmatrix.elements[5] = matrix.elements[6];
        originalmatrix.elements[6] = matrix.elements[8];
        originalmatrix.elements[7] = matrix.elements[9];
        originalmatrix.elements[8] = matrix.elements[10];

        const newMatrix = ViewerToolkit.multiplyMatrix3Matrices(originalmatrix, rotatedmatrix);

        matrix.elements[0] = newMatrix.elements[0];
        matrix.elements[1] = newMatrix.elements[1];
        matrix.elements[2] = newMatrix.elements[2];
        matrix.elements[4] = newMatrix.elements[3];
        matrix.elements[5] = newMatrix.elements[4];
        matrix.elements[6] = newMatrix.elements[5];
        matrix.elements[8] = newMatrix.elements[6];
        matrix.elements[9] = newMatrix.elements[7];
        matrix.elements[10] = newMatrix.elements[8];

        return matrix;
    }
    static multiplyMatrix3Matrices(matrixA, matrixB) {
        // Create a new Matrix3 to store the result
        const result = new THREE.Matrix3();

        // Extract elements from matrices
        const a = matrixA.elements;
        const b = matrixB.elements;
        const r = result.elements;

        // Perform matrix multiplication
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                r[i * 3 + j] =
                    a[i * 3 + 0] * b[0 * 3 + j] +
                    a[i * 3 + 1] * b[1 * 3 + j] +
                    a[i * 3 + 2] * b[2 * 3 + j];
            }
        }

        return result;
    }
}

