/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { ManipulatedElement, ManipulationTypes } from "@/models/ForgeModels";
import store from "@/store";
import { getModelElementBoundingBox2D, getWorldBoundingBox } from '@/store/modules/ForgeService';
import ViewerToolkit from "../Viewer.Toolkit";
import { isTouchDevice } from "@/services/compat";
import { toast } from 'vue3-toastify';
const { Autodesk, THREE } = window;

const VBR = Autodesk.Viewing.Private.VertexBufferReader;

var VBB_GT_TRIANGLE_INDEXED = 0,
    VBB_GT_LINE_SEGMENT = 1,
    VBB_GT_ARC_CIRCULAR = 2,
    VBB_GT_ARC_ELLIPTICAL = 3,
    VBB_GT_TEX_QUAD = 4,
    VBB_GT_ONE_TRIANGLE = 5;

var TAU = Math.PI * 2;

let dbIdTransform = {};

VBR.prototype.transformObject = function (dbId, trans) {
    if (this.useInstancing) {

        ////////
        //TODO....
        console.log(`transformObject: ${dbId}`)
        ////////
    }
    else {

        var i = 0;

        //check the continuous triangles, to avoid double translating the same vertex of triangle
        var ibArrayToMove = [];

        while (i < this.ib.length) {
            var vi = this.ib[i];
            var flag = this.getVertexFlagsAt(vi);

            //var vertexId    = (flag >>  0) & 0xff;        //  8 bit
            var geomType = (flag >> 8) & 0xff;        //  8 bit
            //var linePattern = (flag >> 16) & 0xff;        //  8 bit
            var layerId = this.getLayerIndexAt(vi);    // 16 bit
            var vpId = this.getViewportIndexAt(vi); // 16 bit

            var visible = this.getDbIdAt(vi) === dbId;

            if (geomType === VBB_GT_TRIANGLE_INDEXED) {

                //Triangles are encoded in three vertices (like a simple mesh) instead of 4 like everything else 

                if (visible) {
                    ibArrayToMove.push(this.ib[i]);
                    ibArrayToMove.push(this.ib[i + 1]);
                    ibArrayToMove.push(this.ib[i + 2]);

                    //do not translate at this moment because we have not yet known if the next primitive is
                    //one more triangle
                }

                //Advance to the next primitive
                i += 3;

            } else {

                if (ibArrayToMove.length > 0) {
                    //remove duplicated vertices 
                    let unique_ibArrayToMove = [...new Set(ibArrayToMove)];
                    //translate all vertices now
                    this.transformTriangleIndexed(unique_ibArrayToMove, layerId, vpId, trans);

                    //reset the array for next continuous triangle
                    //good way to clear an array? no memory leak?
                    //see https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
                    ibArrayToMove = [];
                }


                if (visible) {
                    switch (geomType) {
                        case VBB_GT_LINE_SEGMENT: this.transformLine(vi, layerId, vpId, trans);
                            break;
                        case VBB_GT_ARC_CIRCULAR: this.transformCircleArc(vi, layerId, vpId, trans);
                            break;
                        case VBB_GT_ARC_ELLIPTICAL:
                            break;
                        //case VBB_GT_TEX_QUAD:            //TODO break;
                        //case VBB_GT_ONE_TRIANGLE:        //TODO break;
                        default:
                            break;
                    }
                }

                //Skip duplicate vertices (when not using instancing and the geometry is not a simple polytriangle,
                //each vertex is listed four times with a different vertexId flag
                i += 6;
            }

        }
    }


    //update the source buffer of the vertex
    this.vb = this.vbf.buffer;
}

//custom function: transform vertex of a line
//layer, vpId: reserved arguments
VBR.prototype.transformLine = function (vindex, layer, vpId, trans) {

    var baseOffset = this.stride * vindex;

    //For a line segment (on desktop machine) 
    //there will be four vertices that make a quad which corresponds to the line segment. 

    var i = 0;
    while (i < 4) {
        this.vbf[baseOffset + i * this.stride] += trans.x;
        this.vbf[baseOffset + i * this.stride + 1] += trans.y;
        i++;
    }
}

//custom function: transform vertex of a circle arc & circle 
//layer, vpId: reserved arguments 
VBR.prototype.transformCircleArc = function (vindex, layer, vpId, trans) {

    var baseOffset = this.stride * vindex;

    //arc or circle is also fit by line segments.
    // For a line segment (on desktop machine) 
    //there will be four vertices that make a quad which corresponds to the line segment. 
    var i = 0;
    while (i < 4) {
        this.vbf[baseOffset + i * this.stride] += trans.x;
        this.vbf[baseOffset + i * this.stride + 1] += trans.y;
        i++;
    }
}

//custom function: transform vertex of triangles
//layer, vpId: reserved arguments 
VBR.prototype.transformTriangleIndexed = function (ibArray, layer, vpId, trans) {
    var k = 0;
    while (k < ibArray.length) {
        var baseOffset = this.stride * ibArray[k];
        this.vbf[baseOffset] += trans.x;
        this.vbf[baseOffset + 1] += trans.y;
        k++;
    }
};

export default class Translate2DTool extends Autodesk.Viewing.ToolInterface {
    constructor(viewer, clone2dTool) {
        super();

        this.clone2dTool = clone2dTool;

        this.overlayName = 'Translate2dToolOverlay';

        this.priority = 999;

        this.names = ['Viewing.Translate2DTool.Tool'];

        this.contextCallBackName = 'translate2d';

        this.keys = {};

        this.active = false;

        this.viewer = viewer;

        this.transformMesh = null;

        this.transformControlTx = null;

        this.translationDiffWorld = new THREE.Vector3();

        this.translationDiffSheet = new THREE.Vector3();

        this.hitPoint = null;

        this.isDragging = false;

        this.saveTimeout = null;

        this.touchType = null;

        this.viewer.toolController.registerTool(this);

        this.onTxChange = this.onTxChange.bind(this);
        this.onCameraChanged = this.onCameraChanged.bind(this);
        this.onAggregateSelectionChanged = this.onAggregateSelectionChanged.bind(this);
        this.get2ElementsSharedIn2dAnd3d = this.get2ElementsSharedIn2dAnd3d.bind(this);

        delete this.getNames;
        delete this.getName;
        delete this.activate;
        delete this.getPriority;
        delete this.deactivate;
        delete this.handleButtonDown;
        delete this.handleButtonUp;
        delete this.handleMouseMove;
        delete this.handleKeyDown;
        delete this.handleKeyUp;
        delete this.handleGesture;

        this.elements2dPoints = [];
        this.elements3dPoints = [];
        // ViewerToolkit.getMappingPoints(window.parallelViewer, this.viewer, store).then((obj) => {
        //     console.log(';;;;;;;;', obj);
        //     this.elements2dPoints = obj.elements2dPoints;
        //     this.elements3dPoints = obj.elements3dPoints;
        // });
        // this.activate();
    }

    enable(enable) {
        var name = this.getName();

        if (enable) {
            this.viewer.toolController.activateTool(name);
        } else {
            this.viewer?.toolController?.deactivateTool(name);
        }
    }

    getPriority() {
        return this.priority;
    }

    getNames() {
        return this.names;
    }

    getName() {
        return this.names[0];
    }

    activate() {
        if (!this.active) {
            this.active = true;
            //this.addContextMenuOption();

            this.createControls();
        }
    }

    deactivate() {
        console.log('Translate2D deActivated');
        if (this.active) {
            this.active = false;

            this.removeControls();

            this.removeContextMenuOption();

            this.elements2dPoints = [];
            this.elements3dPoints = [];
        }
    }

    removeControls() {
        this.translationDiffWorld = new THREE.Vector3();

        this.translationDiffSheet = new THREE.Vector3();

        this.viewer.impl.removeOverlay(this.overlayName, this.transformControlTx);

        this.transformControlTx.removeEventListener('change', this.onTxChange);

        this.viewer.impl.removeOverlayScene(this.overlayName);

        this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);
    }

    createControls() {
        var bbox = this.viewer.model.getBoundingBox();

        this.viewer.impl.createOverlayScene(this.overlayName);

        this.transformControlTx = new THREE.TransformControls(
            this.viewer.impl.camera,
            this.viewer.impl.canvas,
            'translate',
        );

        this.transformControlTx.setSize(bbox.getBoundingSphere().radius * 10);

        this.transformControlTx.visible = false;

        this.viewer.impl.addOverlay(this.overlayName, this.transformControlTx);

        this.transformMesh = this.createTransformMesh();

        this.transformControlTx.attach(this.transformMesh);
    }

    createTransformMesh() {
        var material = new THREE.MeshPhongMaterial({ color: 0xff0000 });

        this.viewer.impl.matman().addMaterial('translate-2d-tool-material', material, true);

        var sphere = new THREE.Mesh(new THREE.SphereGeometry(0.0001, 10), material);

        sphere.position.set(0, 0, 0);

        return sphere;
    }

    addContextMenuOption() {
        this.viewer.registerContextMenuCallback(this.contextCallBackName, (menu, status) => {
            if (status.hasSelected) {
                menu.push({
                    title: 'Move Element',
                    target: this.moveElement.bind(this),
                });
            }
        });
    }

    removeContextMenuOption() {
        this.viewer.unregisterContextMenuCallback(this.contextCallBackName);
    }

    resetManipulatedElement(dbId) {
        if (!dbId) return;

        const model = window.NOP_VIEWER.model;

        const objectId = model.reverseMapDbIdFor2D(dbId);

        const it = model.getInstanceTree();

        if (!it) return;

        let translate = dbIdTransform[dbId].clone().negate();

        if (!translate) return;

        delete dbIdTransform[dbId];

        it.enumNodeFragments(dbId, (fragId) => {
            var m = this.viewer.impl.getRenderProxy(model, fragId);

            var vbr = new VBR(m.geometry, this.viewer.impl.use2dInstancing);

            vbr.transformObject(objectId, translate);

            m.geometry.vbNeedsUpdate = true;

            this.viewer.impl.sceneUpdated();
        });

        this.viewer.setThemingColor(dbId, null);

        this.viewer.impl.invalidate(true);

        this.clearSelection();
    }

    moveExistingElement(dbId, position) {
        if (!this.active || !dbId || !position) return;

        const model = window.NOP_VIEWER.model;

        const objectId = model.reverseMapDbIdFor2D(dbId);

        const it = model.getInstanceTree();

        if (!it) return;

        var nodebBox = getModelElementBoundingBox2D(dbId, model);

        let elementCenter = nodebBox.getCenter();

        let translate = position.clone().sub(elementCenter);

        let exist = dbIdTransform[dbId] ?? new THREE.Vector3(0, 0, 0);

        dbIdTransform[dbId] = exist.add(translate);

        it.enumNodeFragments(dbId, (fragId) => {
            var m = this.viewer.impl.getRenderProxy(model, fragId);

            var vbr = new VBR(m.geometry, this.viewer.impl.use2dInstancing);

            vbr.transformObject(objectId, translate);

            m.geometry.vbNeedsUpdate = true;

            this.viewer.impl.sceneUpdated();
        });

        this.viewer.impl.invalidate(true);
    }

    translateSelectionToDirection(direction, sensitivity) {
        if (!this.active || !this.selection || !this.transformControlTx) return;

        let value = sensitivity / 1000;

        let translate = new THREE.Vector3();

        switch (direction) {
            case 'up':
                translate.y += value;
                break;
            case 'down':
                translate.y -= value;
                break;
            case 'left':
                translate.x -= value;
                break;
            case 'right':
                translate.x += value;
                break;
            default:
                break;
        }

        let position = this.transformControlTx.position.clone().add(translate);

        this.transformControlTx.setPosition(position);

        let mappedPosition = ViewerToolkit.sheetToWorld(
            this.viewer,
            position.clone(),
            this.viewer.model,
            store.getters['VIEWER/GLOBAL_OFFSET'],
        );

        if (!mappedPosition) {
            console.log('Failed to translate element');
            return;
        }

        let lastWorldPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];
        lastWorldPosition = new THREE.Vector3(
            lastWorldPosition.x,
            lastWorldPosition.y,
            lastWorldPosition.z,
        );
        let lastSheetPosition = ViewerToolkit.worldToSheet(
            lastWorldPosition,
            this.viewer.model,
            store.getters['VIEWER/TRANSFORM_MATRIX'],
        );

        this.translationDiffSheet = position.clone().sub(lastSheetPosition);
        this.translationDiffWorld = mappedPosition.clone().sub(lastWorldPosition);

        store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', mappedPosition.clone());

        this._update();
    }

    async moveElement(e) {
        const selections = this.viewer.getAggregateSelection();

        if (!selections || !selections.length) {
            this.clearSelection();
            return;
        }

        this.selection = selections[0];

        // const dbId = this.selection.selection[0];

        let selectedModelId = selections[0]?.model?.id;

        if (!selectedModelId) return;

        let familyInstance = store.state.familiesInstances.find(
            (family) => family.loadedModelId === selectedModelId,
        );

        if (familyInstance) return;

        let mergedBox = new THREE.Box3();

        this.selection.selection.forEach((dbId) => {
            var nodebBox = getModelElementBoundingBox2D(dbId, this.selection.model);
            mergedBox.union(nodebBox);
        });

        this.hitPoint = mergedBox.getCenter();
        let mappedPosition = ViewerToolkit.sheetToWorld(
            this.viewer,
            this.hitPoint.clone(),
            this.viewer.model,
            store.getters['VIEWER/GLOBAL_OFFSET'],
        );
        if (!mappedPosition) {
            mappedPosition = await this.tryGetMappedWorldPositions(this.hitPoint.clone());
            if (!mappedPosition) {
                toast.error('Viewport not found');
                this.clearSelection();
                console.log('Failed to get start position');
                return;
            }
        }

        this.transformControlTx.visible = true;

        this.transformControlTx.setPosition(this.hitPoint);

        this.transformControlTx.addEventListener('change', this.onTxChange);

        this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

        this.viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );

        this.transformControlTx.update();

        this.viewer.impl.sceneUpdated(true);

        store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', mappedPosition.clone());

        store.commit('VIEWER/SET_START_MANIPULATION_POSITION', mappedPosition.clone());
    }
    findLeafNodes(model) {
        return new Promise(function (resolve, reject) {
            model.getObjectTree(function (tree) {
                let leaves = [];
                tree.enumNodeChildren(
                    tree.getRootId(),
                    function (dbid) {
                        if (tree.getChildCount(dbid) === 0) {
                            leaves.push(dbid);
                        }
                    },
                    true,
                );
                resolve(leaves);
            }, reject);
        });
    }
    get2ElementsSharedIn2dAnd3d(parallelViewer, viewId, allDbIds, findLeafNodes) {
        return new Promise(function (resolve, reject) {
            let root = parallelViewer?.model?.getDocumentNode()?.getRootNode();
            if (!root) {
                console.log(root);
            }
            let views = root.search({ guid: viewId });
            if (!views.length) return;

            let view = views[0];
            const doc = parallelViewer.model.getDocumentNode().getRootNode().lmvDocument;
            let callback = async () => {
                parallelViewer.removeEventListener(
                    Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                    callback,
                );
                const allDbIdsInCurrentModel = await findLeafNodes(parallelViewer.model);
                const result = [];

                for (let i = 0; i < allDbIds.length; i++) {
                    for (let j = 0; j < allDbIdsInCurrentModel.length; j++) {
                        if (
                            allDbIds[i] === allDbIdsInCurrentModel[j] &&
                            !result.includes(allDbIds[i])
                        ) {
                            result.push(allDbIds[i]);
                        }
                        if (result.length === 2) {
                            let output = {};
                            let it = parallelViewer.model.getInstanceTree();

                            let fragList = parallelViewer.model.getFragmentList();
                            for (let index = 0; index < 2; index++) {
                                let fragIds = [];

                                it.enumNodeFragments(result[index], (fragId) => {
                                    fragIds.push(fragId);
                                });

                                let nodebBox = getWorldBoundingBox(fragIds, fragList);

                                let center = nodebBox.getCenter();

                                output[result[index]] = center;
                            }
                            resolve(output);
                            return;
                        }
                    }
                }
                resolve({});
            };

            parallelViewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, callback);
            parallelViewer.loadDocumentNode(doc, view);
        });
    }
    async getMappingPoints(viewer3D, viewer2D) {
        let sharedDbIds = [];
        const allDbIds = await this.findLeafNodes(viewer2D.model);
        for (let i = 0; i < store.getters.D3ViewItems.length; i++) {
            const guid = store.getters.D3ViewItems[i].data.guid;
            sharedDbIds = await this.get2ElementsSharedIn2dAnd3d(
                viewer3D,
                guid,
                allDbIds,
                this.findLeafNodes,
            );
            if (Object.keys(sharedDbIds).length == 2) break;
        }
        // if (sharedDbIds.length != 2) return;
        const outputDbids = Object.keys(sharedDbIds);
        var nodebBox1 = getModelElementBoundingBox2D(parseInt(outputDbids[0]), viewer2D.model);
        let elementCenter1 = nodebBox1.getCenter();

        var nodebBox2 = getModelElementBoundingBox2D(parseInt(outputDbids[1]), viewer2D.model);
        let elementCenter2 = nodebBox2.getCenter();

        const center1_3d = sharedDbIds[outputDbids[0]];
        const center2_3d = sharedDbIds[outputDbids[1]];

        const elements2dPoints = [elementCenter1, elementCenter2];
        const elements3dPoints = [center1_3d, center2_3d];
        return { elements2dPoints, elements3dPoints };
    }
    async tryGetMappedWorldPositions(hitPoint) {
        await this.checkMappingPoints();
        const mappedPoint = ViewerToolkit.map2DTo3D(
            hitPoint,
            this.elements2dPoints,
            this.elements3dPoints,
        );
        return mappedPoint;
    }
    async tryGetMappedSheetPositions(hitPoint) {
        await this.checkMappingPoints();
        const mappedPoint = ViewerToolkit.map3DTo2D(
            hitPoint,
            this.elements3dPoints,
            this.elements2dPoints,
        );
        return mappedPoint;
    }
    async checkMappingPoints() {
        if (this.elements2dPoints.length && this.elements3dPoints.length) return;
        const mappingPoints = await this.getMappingPoints(window.parallel3dViewer, this.viewer);
        this.elements2dPoints = mappingPoints.elements2dPoints;
        this.elements3dPoints = mappingPoints.elements3dPoints;
    }

    onAggregateSelectionChanged(event) {
        this.clearSelection();

        if (event.selections.length) {
            this.moveElement();
        }
    }

    onTxChange() {
        this.viewer.impl.sceneUpdated(true);
    }

    onCameraChanged() {
        if (this.transformControlTx) this.transformControlTx.update();
    }

    clearSelection() {
        if (!this.active) return;

        this.selection = null;

        this.transformControlTx.visible = false;

        this.transformControlTx.removeEventListener('change', this.onTxChange);

        this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

        this.viewer.removeEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );

        this.viewer.impl.sceneUpdated(true);

        store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', null);

        store.commit('VIEWER/SET_START_MANIPULATION_POSITION', null);

        this.translationDiffSheet = new THREE.Vector3();

        this.translationDiffWorld = new THREE.Vector3();
    }

    handleSingleClick(event, button) {
        if (this.transformControlTx?.onPointerDown(event)) return true;

        return false;
    }

    handleButtonDown(event, button) {
        if (button !== 0) return false;

        this.isDragging = true;

        if (this.transformControlTx?.onPointerDown(event)) return true;

        return !!this.selection;
    }

    handleButtonUp(event, button) {
        this.isDragging = false;

        if (button !== 0) return false;

        if (this.transformControlTx?.onPointerUp(event)) return true;

        return !!this.selection;
    }

    handleSingleTap(event) {
        // if (this.transformControlTx?.onTap(event)) return true;

        console.log('Single tap event triggered');

        return false;
    }

    handleDoubleTap(event) {
        // if (this.transformControlTx?.onDoubleTap(event)) return true;

        console.log('Double tap event triggered');

        return false;
    }

    handleGesture(event) {
        if (!isTouchDevice()) return false;

        if (!this.selection) return false;

        console.log('Gesture event triggered from Translate2DTool');

        switch (event.type) {
            case 'dragstart':
                var accepted = this.transformControlTx?.onPointerDown(event);

                this.handleButtonDown(event, 0);

                this.touchType = 'drag';

                return accepted;

            case 'dragmove':
                return this.handleMouseMove(event);

            case 'dragend':
                if (this.touchType === 'drag') {
                    this.handleButtonUp(event, 0);

                    this.touchType = null;

                    return true;
                }

                return false;
        }

        return false;
    }

    handleMouseMove(event) {
        if (this.isDragging) {
            if (this.transformControlTx?.onPointerMove(event) && this.selection) {
                let position = this.transformControlTx.position;

                let mappedPosition = ViewerToolkit.sheetToWorld(
                    this.viewer,
                    position.clone(),
                    this.viewer.model,
                    store.getters['VIEWER/GLOBAL_OFFSET'],
                );

                if (!mappedPosition) {
                    mappedPosition = ViewerToolkit.map2DTo3D(
                        position.clone(),
                        this.elements2dPoints,
                        this.elements3dPoints,
                    );

                    if (!mappedPosition) {
                        console.log('Failed to translate element');
                        return;
                    }
                }

                let lastWorldPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];
                lastWorldPosition = new THREE.Vector3(
                    lastWorldPosition.x,
                    lastWorldPosition.y,
                    lastWorldPosition.z,
                );
                let lastSheetPosition = ViewerToolkit.worldToSheet(
                    lastWorldPosition,
                    this.viewer.model,
                    store.getters['VIEWER/TRANSFORM_MATRIX'],
                );
                if (!lastSheetPosition) {
                    lastSheetPosition = ViewerToolkit.map3DTo2D(
                        lastWorldPosition,
                        this.elements3dPoints,
                        this.elements2dPoints,
                    );
                }
                this.translationDiffSheet = position.clone().sub(lastSheetPosition);
                this.translationDiffWorld = mappedPosition.clone().sub(lastWorldPosition);

                store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', mappedPosition.clone());

                this._update();

                return true;
            }

            return false;
        }

        if (this.transformControlTx?.onPointerHover(event)) {
            return true;
        }

        return false;
    }

    forceUpdate() {
        let worldPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];

        let position = ViewerToolkit.worldToSheet(
            worldPosition,
            this.viewer.model,
            store.getters['VIEWER/TRANSFORM_MATRIX'],
        );

        if (!position) {
            position = ViewerToolkit.map3DTo2D(
                worldPosition.clone(),
                this.elements3dPoints,
                this.elements2dPoints,
            );
            if (!position) {
                console.log('Failed to translate element');
                return;
            }
        }

        let lastSheetPosition = this.transformControlTx.position.clone();
        let lastWorldPosition = ViewerToolkit.sheetToWorld(
            this.viewer,
            lastSheetPosition,
            this.viewer.model,
            store.getters['VIEWER/GLOBAL_OFFSET'],
        );
        if (!lastWorldPosition) {
            lastWorldPosition = ViewerToolkit.map2DTo3D(
                lastSheetPosition.clone(),
                this.elements2dPoints,
                this.elements3dPoints,
            );
        }
        lastWorldPosition = new THREE.Vector3(
            lastWorldPosition.x,
            lastWorldPosition.y,
            lastWorldPosition.z,
        );

        this.translationDiffSheet = position.clone().sub(lastSheetPosition);
        this.translationDiffWorld = worldPosition.clone().sub(lastWorldPosition);

        this.transformControlTx.setPosition(position);

        this._update();
    }

    async _update() {
        if (!this.selection?.selection?.length) return;

        const it = this.viewer.model.getInstanceTree();

        if (!it) return;

        let worldPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];

        worldPosition = new THREE.Vector3(worldPosition.x, worldPosition.y, worldPosition.z);

        let sheetPosition = ViewerToolkit.worldToSheet(
            worldPosition,
            this.viewer.model,
            store.getters['VIEWER/TRANSFORM_MATRIX'],
        );

        if (!worldPosition) {
            console.log('Failed to translate element');
            return;
        }

        this.selection.selection.forEach((dbId) => {
            //const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(this.selection.model, dbId, store);
            const objectMappedDbId = this.viewer.model.reverseMapDbIdFor2D(dbId);

            var nodebBox = getModelElementBoundingBox2D(dbId, this.selection.model);

            let elementCenter = nodebBox.getCenter();

            let elementNewSheetPosition = elementCenter.clone().add(this.translationDiffSheet);

            elementCenter = ViewerToolkit.sheetToWorld(
                this.viewer,
                elementCenter.clone(),
                this.viewer.model,
                store.getters['VIEWER/GLOBAL_OFFSET'],
            );
            if (!elementCenter) {
                elementCenter = ViewerToolkit.map2DTo3D(
                    nodebBox.getCenter().clone(),
                    this.elements2dPoints,
                    this.elements3dPoints,
                );
            }
            let elementNewWorldPosition = ViewerToolkit.sheetToWorld(
                this.viewer,
                elementNewSheetPosition.clone(),
                this.viewer.model,
                store.getters['VIEWER/GLOBAL_OFFSET'],
            );
            if (!elementNewWorldPosition) {
                elementNewWorldPosition = ViewerToolkit.map2DTo3D(
                    elementNewSheetPosition.clone(),
                    this.elements2dPoints,
                    this.elements3dPoints,
                );
            }
            let exist = dbIdTransform[dbId] ?? new THREE.Vector3(0, 0, 0);

            dbIdTransform[dbId] = exist.add(this.translationDiffSheet);

            it.enumNodeFragments(dbId, (fragId) => {
                var m = this.viewer.impl.getRenderProxy(this.viewer.model, fragId);

                var vbr = new VBR(m.geometry, this.viewer.impl.use2dInstancing);

                vbr.transformObject(objectMappedDbId, this.translationDiffSheet);

                m.geometry.vbNeedsUpdate = true;

                this.viewer.impl.sceneUpdated();
            });

            let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
                (element) =>
                    element.dbId == dbId &&
                    element.manipulationType == ManipulationTypes.MANIPULATE_EXISTING,
            );

            if (!manipulatedElement) {
                ViewerToolkit.getViewabelIdsOfElement(this.selection.model, dbId, store).then(
                    (_viewGuids) => {
                        this.viewer.getProperties(dbId, (props) => {
                            manipulatedElement = new ManipulatedElement();
                            manipulatedElement.viewType = '2d';
                            manipulatedElement.viewId =
                                window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                            manipulatedElement.manipulationType =
                                ManipulationTypes.MANIPULATE_EXISTING;
                            manipulatedElement.name = props.name;
                            manipulatedElement.modelId = this.viewer.model.getData().urn;
                            manipulatedElement.dbId = dbId;
                            manipulatedElement.originalDbId = dbId;
                            manipulatedElement.originalExternalId = props.externalId;
                            manipulatedElement.position = elementNewWorldPosition.clone();
                            manipulatedElement.originalPosition = elementCenter.clone();
                            manipulatedElement.viewGuids = _viewGuids;
                            manipulatedElement.rotation = null;

                            store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);
                        });
                    },
                );
            } else {
                manipulatedElement.position = new THREE.Vector3(
                    elementNewWorldPosition.x,
                    elementNewWorldPosition.y,
                    manipulatedElement.position.z,
                );
            }
        });

        // let rotateTool = this.viewer.toolController.getTool('Viewing.Rotate2D.Tool');

        // if (rotateTool && rotateTool.active && rotateTool.rotateControl) {
        //     rotateTool.drawControl();
        // }

        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
        }

        this.saveTimeout = setTimeout(() => {
            store.dispatch('SaveLocalModelChanges');
        }, 2000);
    }
}